import React from 'react';
import NotificationEditor from '../../../components/NotificationEditor/NotificationEditor';

function Notifications() {

  return (
    <NotificationEditor groupId={0} />
  )

}

export default Notifications;
