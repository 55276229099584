import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Level, Heading, Button } from 'react-bulma-components';
import { getGroups } from '../../../lib/api';

import CohortsTable from '../../../components/CohortsTable/CohortsTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

import { useRights } from '../../../lib/auth';

function Groups() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);

  const { isAdmin } = useRights();

  useEffect(() => {
    getGroups()
      .then((data) => {
        console.log('data', data);
        setGroupData(data);
      })
      .catch((err) => {
        console.error('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function newGroup() {
    history.push(`/admin/cohorts/new`);
  }

  return (
    <>
      {isLoading ? <ProgressComponent /> :
        <div className="admin-group-page">
          <Level>
            <div className="level-left">
              <div className="level-item">
                <Heading>Cohorts</Heading>
              </div>
            </div>
            <div className="level-right">
              {isAdmin && <Button className="is-success" onClick={newGroup}>Create Cohort</Button>}
            </div>
          </Level>
          <CohortsTable cohorts={groupData} />
          {/* <CohortsTable cohorts={cohortData} deleteTitle="Remove" onDeleteClick={handleDeleteCohortClick} /> */}
        </div>
      }
    </>
  )
}

export default Groups;
