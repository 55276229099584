import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, } from 'react-bulma-components';
import Select from 'react-select';
import { getPossibleManualMatches, manualMatch } from '../../lib/api';

const ManualMatch = forwardRef(function ManualMatch(props, ref) {
  const { groupId, onIsMatching } = props;

  const [ manualMatchMentorId, setManualMatchMentorId ] = useState();
  const [ manualMatchMenteeId, setManualMatchMenteeId ] = useState();
  const [ manualMatchErrorText, setManualMatchErrorText ] = useState();
  const [ manualMatchInProgress, setManualMatchInProgress ] = useState(false);
  //const [ manualMatchFreeMentor, setManualMatchFreeMentor ] = useState(true);
  const [ pageData, setPageData ] = useState();
  const [ isLoading, setIsLoading ] = useState(true);
  
  const formatOptionLabel = ({ value, label, data }) => (
    <div>
      <div>{label}</div>
      <div className="has-text-grey" style={{ fontSize: 'smaller' }}>
        <div>{data.email}</div>
        {/* <div>{data.companyname}</div> */}
      </div>
    </div>
  );
  
  useImperativeHandle(ref, () => ({
    onDoneClicked() {
      return doManualMatch();
    },
  }));

  useEffect(() => {
    getPossibleManualMatches()
      .then((data) => {

        function groupFilter(f) {
          return groupId ? f.group_id === parseInt(groupId) : true
        }

        setPageData({
          mentees: data.mentees.filter(groupFilter).sort((a,b) => a.first_name.localeCompare(b.first_name)),
          mentors: data.mentors.filter(groupFilter).sort((a,b) => a.first_name.localeCompare(b.first_name)),
          mentorsForMatching: data.mentors.filter(groupFilter).sort((a,b) => a.first_name.localeCompare(b.first_name)),
        })
      })
      .catch((err) => {
        console.error('err', err)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [groupId])

  async function doManualMatch () {
    let success = false;
    setManualMatchErrorText('');

    try {
      if (manualMatchMentorId && manualMatchMenteeId) {
        setManualMatchInProgress(true);
        if (onIsMatching) onIsMatching(true);

        await manualMatch(manualMatchMentorId, manualMatchMenteeId)//, manualMatchFreeMentor);
        success = true;
      }
    else {
      setManualMatchErrorText("Please select a mentor and a mentee.");
    }

    return success;
  }

  catch(err) {
    console.error('err', err.response.data);
    setManualMatchErrorText(err?.response?.data || err.message);
  }
  finally {
    setManualMatchInProgress(false);
    if (onIsMatching) onIsMatching(false);
  }

  return success;
  
}

  return <>
      <div className="is-flex" style={{ alignItems: 'end' }}>
        <div className="mr-2" style={{width: '100%'}}>
          <Form.Field>
            <Form.Label>Mentor</Form.Label>
            <Select isLoading={isLoading} disabled={manualMatchInProgress} onChange={(e) => { setManualMatchMentorId(e.value) }} menuPortalTarget={document.body}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), menu: provided => ({...provided, width: 350}) }}
                formatOptionLabel={formatOptionLabel} options={pageData?.mentors && pageData.mentorsForMatching.map((m) => { return { value: m.id, label: `${m.first_name} ${m.last_name}`, data: m }})}>
                </Select>
            </Form.Field>
        </div>
        <div className="ml-2" style={{width: '100%'}}>
          <Form.Field>
            <Form.Label>Mentee</Form.Label>
            <Select isLoading={isLoading} disabled={manualMatchInProgress} onChange={(e) => { setManualMatchMenteeId(e.value) }} menuPortalTarget={document.body}  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), menu: provided => ({...provided, width: 350}) }}
              formatOptionLabel={formatOptionLabel} options={pageData?.mentees && pageData.mentees.map((m) => { return { value: m.id, label: `${m.first_name} ${m.last_name}`, data: m }})}>
            </Select>
          </Form.Field>
        </div>
        {/* <div>
            <Button className="is-info" loading={manualMatchInProgress} onClick={doManualMatch}>Match</Button>
          </div> */}
      </div>
      <div className="is-text-danger">
        {manualMatchErrorText && <Form.Help color="danger">Match failed: {manualMatchErrorText}</Form.Help>}
      </div>

      {/*<Form.Field className="mt-3">
        <input className="is-checkradio is-info" id="free_mentee" type="checkbox" name="free_mentee" defaultChecked={manualMatchFreeMentor === true ? "checked" : ""} onChange={e => setManualMatchFreeMentor(e.target.checked)} />
        <label htmlFor="free_mentee">Make Mentorship FREE</label>
      </Form.Field> */}
      </>

});

export default ManualMatch;