import { useState } from 'react';
import { useStore } from '../../store/Store';
import { Modal, Button } from 'react-bulma-components';
import { patchUser, patchUserAsAdmin } from '../../lib/api';
import ContactInfoForm from '../ContactInfoForm/ContactInfoForm';
import { useRights } from '../../lib/auth';


function CreateProfileDetailsModal({open, onClose, onSaved, user, isMentor}) {
  const { dispatch } = useStore();
  const { isAdmin } = useRights();
  const [ isSaving, setIsSaving] = useState();
  const [ doSubmit, setDoSubmit] = useState();

  const initialUserData = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    zipCode: user?.zipCode,
    linkedinProfile: user?.linkedinProfile,
    workCompany: user?.workCompany,
    birthDate: user?.birthDate,
  }

  async function onSubmit(formData) {
    // Reset the validator
    setDoSubmit(false);

    if (formData.valid) {
      try {
        setIsSaving(true);
        const patchedUserData = isAdmin ? await patchUserAsAdmin(user, formData.data) : await patchUser(isMentor, user, formData.data, dispatch);
        if (onSaved) {
          onSaved(patchedUserData);
        }
      }
      catch (err) {
        console.error('Error updating info', err);
      }
      finally {
        setIsSaving(false);
      }
    }
  }

  return (
    <Modal showClose={false} show={open} onClose={() => onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">Contact Info</p>
        </Modal.Card.Header>
          <Modal.Card.Body>
            <ContactInfoForm user={initialUserData} isMentor={isMentor} doSubmit={doSubmit} handleOnSubmitResult={onSubmit}/>
          </Modal.Card.Body>
          <Modal.Card.Footer className="modal-card-foot is-flex is-justify-content-flex-end">
            <Button className="button is-success" loading={isSaving} onClick={() => setDoSubmit(true)}>Apply</Button>
            <Button className="button" disabled={isSaving} onClick={() => onClose()}>Cancel</Button>
          </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default CreateProfileDetailsModal;