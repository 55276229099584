import config from '../../config';
import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCopy, faLink } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bulma-components';

import TernTable from '../TernTable/TernTable';

function CohortsTable({ cohorts, onDeleteClick, deleteTitle = 'Delete' }) {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {

    const sortedData = [...cohorts].sort((a, b) => a.name.localeCompare(b.name));
    cohorts.sort((a, b) => a.name.localeCompare(b.name));
    setTableData(sortedData);
  }, [cohorts])

  useEffect(() => console.log(tableData), [tableData])

  const data = useMemo(
    () => tableData,
    [tableData]
  )


  const columns = useMemo(
    () => [
      // {
      //   Header: 'Name',
      //   accessor: 'name', 
      // },
      // {
      //   Header: 'Description',
      //   accessor: 'description',
      // },
      {
        Header: 'Company name',
        accessor: 'companyName',
      },
      {
        Header: 'Company website',
        accessor: 'companyWebsite',
      },
      {
        Header: 'Signup Code',
        accessor: 'signupCode',
        Cell: props => <span>{props.value}<button className="ml-2" title="Copy Code" onClick={(e) => { copySignup(props.value, false); e.stopPropagation(); }}><FontAwesomeIcon icon={faCopy} /></button><button className="ml-2" title="Copy URL" onClick={(e) => { copySignup(props.value, true); e.stopPropagation(); }}><FontAwesomeIcon icon={faLink} /></button></span>
      },
      {
        Header: 'Enabled',
        accessor: 'enabled',
        collapse: true,
        Cell: props => <span>{props.value ? 'Yes' : 'No'}</span>
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        collapse: true,
        Cell: prop => onDeleteClick ? <Button className="is-small" title={deleteTitle} onClick={(e) => { onDeleteClick({ id: prop.value, rows: prop.rows}); e.stopPropagation(); }}><FontAwesomeIcon icon={faTrash} /></Button> : null
      },
    ],
    [onDeleteClick, deleteTitle]
  )

  function copySignup(code, asUrl) {
    const toCopy = asUrl ? `${config.appUrl}signup?signupcode=${code}` : code;
    navigator.clipboard.writeText(toCopy);
    toast.success(`Signup ${asUrl ? 'URL' : 'code'} copied to clipboard`);
    return false;
  }

  function onTableRowClick(row) {
    history.push(`/admin/cohorts/${row.id}`);
  }

  return (
    <TernTable hover={true} showTotal={false} columns={columns} data={data} initialSort={[{ id: 'companyName', desc: false }]} onRowClick={onTableRowClick} />
  )
}

export default CohortsTable;
