import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bulma-components';
import { createUser, patchUserAsAdmin } from '../../lib/api';
import AdminContactInfoForm from '../AdminContactInfoForm/AdminContactInfoForm';

function AdminProfileDetailsModal({ open, onClose, onSaved, user, isMentor, isNewUser }) {
  const [isSaving, setIsSaving] = useState(false);
  const [doSubmit, setDoSubmit] = useState();
  const [ modifiedUser, setModifiedUser] = useState();
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setModifiedUser({
      contactId: user?.contactId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      userTypeId: user?.activeProfile.contactType.id,
    });
  }, [user]);

  async function onSubmit(formData) {
    setFormErrors({});
    // Reset the validator
    setDoSubmit(false);
    const { email, firstName, lastName, userType, password, groupId } = formData.data;
    if (formData.valid) {
      try {
        setIsSaving(true);
        let resultData;

        if (isNewUser) {
          const payload = {
            email,
            firstName,
            lastName,
            password,
            userType,
            groupId,
          };

          resultData = await createUser(payload);
        }
        else {
          const cleansedData = { ...formData.data };
          if (cleansedData.email) {
            delete cleansedData.email;
          }
          resultData = await patchUserAsAdmin(modifiedUser, cleansedData);
        }

        console.log('res', resultData);
        
        if (onSaved) {
          onSaved(resultData);
        }
      } catch (err) {
        console.error('Error updating info', err.response);
        if (err?.response.status === 400 && err.response.data?.errors) {
          const errors = err.response.data?.errors;
          const newFormErrors = {};
          errors.forEach(error => {
            newFormErrors[error.path] = error.message.includes('already exist') ? 'Already exists' : error.message;
          })
          setFormErrors(newFormErrors);
        }
      } finally {
        setIsSaving(false);
      }
    }
  }


  return (
    <Modal showClose={false} show={open} onClose={() => onClose()}>
      <Modal.Card>
        <Modal.Card.Header showClose={true}>
          <p className="modal-card-title">{isNewUser ? 'Create User' : 'Edit User'}</p>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <AdminContactInfoForm user={modifiedUser} isMentor={isMentor} doSubmit={doSubmit} setDoSubmit={setDoSubmit} handleOnSubmitResult={onSubmit} isNewUser={isNewUser} formErrors={formErrors}/>
        </Modal.Card.Body>
        <Modal.Card.Footer className="modal-card-foot is-flex is-justify-content-flex-end">
          <Button className="button is-success" loading={isSaving} onClick={() => setDoSubmit(true)}>Save</Button>
          <Button className="button" disabled={isSaving} onClick={() => onClose()}>Cancel</Button>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  )
};

export default AdminProfileDetailsModal;