import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Form, } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons'

function PictureUploader(props) {
  const [image, setImage] = useState();
  // const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const [imageWasUploaded, setImageWasUploaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function onCancel() {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const onImageUploaded = (e) => {
    console.log('onImageUploaded');

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageWasUploaded(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const dataUrlToFileUsingFetch = async (
    url,
    fileName,
    mimeType
  ) => {

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();

    return new File([buffer], fileName, { type: mimeType });
  };

  const uploadImage = () => {
    if (typeof cropper !== "undefined") {
      let file = cropper.getCroppedCanvas({ height: 400, width: 700 }).toDataURL()
      // setCropData(file);
      handleUpload(file);
    }

  };

  const handleUpload = async (url) => {

    setIsSaving(true);

    try {
      const file = await dataUrlToFileUsingFetch(url, 'output.png', 'image/png')

      const formData = new FormData();
      formData.append('profilepic', file);
      // await uploadBannerPic(props.groupId, formData);

      setImageWasUploaded(false);

      if (props.onSaved) {
        props.onSaved(url);
      }
    }
    catch (err) {
      console.error(`Error uploading banner picture`, err)
    }
    finally {
      setIsSaving(false);
    }
  };

  return (

    <div style={{ maxWidth: 600 }}>

      {image &&
        <div className="py-3">

          <Cropper
            style={{ height: 350, width: "100%" }}
            // zoomTo={0.5}
            aspectRatio={props.format === "landscape" ? 0 : 1}
            preview=".img-preview"
            src={image}
            dragMode={"none"}
            viewMode={0}
            background={false}
            cropBoxResizable={true}
            maxcontainerheight={props.format === "landscape" ? 1280 : 400}
            maxcontainerwidth={props.format === "landscape" ? 1024 : 700}
            scaleX={1}
            scaleY={1}
            responsive={true}
            // moveable={false}
            autoCropArea={1}
            center={false}
            guides={false}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        </div>
      }
      <div className="py-3">

        {!imageWasUploaded &&
          <div className="is-flex is-justify-content-center">
            <Form.InputFile className="is-centered" icon={<FontAwesomeIcon icon={faUpload} />} label="Choose an image..." onChange={onImageUploaded}></Form.InputFile>
          </div>
        }

        {imageWasUploaded &&
          <Button.Group className="is-flex is-justify-content-flex-end">
            <Button className="is-primary" disabled={isSaving} onClick={uploadImage}>
              Save
            </Button>

            {props.onCancel &&
              <Button className="is-danger is-outlined" disabled={isSaving} onClick={onCancel}>
                Cancel
              </Button>
            }

          </Button.Group>
        }

      </div>
    </div>
  );
};

export default PictureUploader;
