import { useMemo, useState, useEffect } from 'react';
import { Heading, Box, Level } from 'react-bulma-components';
import { useHistory } from 'react-router-dom';

import { defaultDateTimeString, capitalize } from '../../../lib/stringHelpers';
import { getAdminDashboardReport } from '../../../lib/api';
import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function AdminDashboard(props) {
  const history = useHistory();
  const [ tableData, setTableData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState();
  
  const columns = useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'last_name', 
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Type',
        accessor: 'profiletype',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Cohort',
        accessor: 'cohortName',
      },
      {
        Header: 'Joined On',
        accessor: 'creation_date',
        dateColumn: true,
      },
    ],
    []
  )

  useEffect(() => {
    getAdminDashboardReport()
      .then(data => {
        setData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (data?.latestUsers) {
      setTableData(data.latestUsers.map((user) => { return {
        id: user.contactId,
        last_name: user.lastName,
        first_name: user.firstName,
        profiletype: capitalize(user.activeProfile.contactType.description),
        email: user.email,
        cohortName: (user.activeProfile.contactType.id === 4 || user.activeProfile.contactType.id === 5)  ? '-' : user.group.name,
        creation_date: defaultDateTimeString(user.creationDate),
      }}));
    }
  }, [data])

  function plusMinusPercentSpan(newValue, oldValue) {
    //let percentage = (oldValue > 0 ? (newValue / oldValue) : (newValue)) * 100;
    let newPercentage = oldValue > 0 ? ((newValue - oldValue)/oldValue) * 100  : newValue * 100;
    let color = newPercentage < 0 ? 'has-text-danger' : 'has-text-success';
    
    
    //let percentageText = percentage < 100 ? `-${percentage.toFixed(0)}%` : `+${(oldValue > 0 ? percentage - 100 : percentage).toFixed(0)}%`
    let percentageText = `${(newPercentage).toFixed(0)}%`
    
    if (percentageText === '-0%') { percentageText = '0%'; }
    return <span className={color} style={{fontSize: 'medium', display: 'block'}}>({percentageText})</span>;
  }

  function sumNewUsers() {
    return data.lastWeekNewMentees + data.lastWeekNewMentors + data.lastWeekNewDonors;
  }

  function sumPrevUsers() {
    return data.previousWeekNewMentees + data.previousWeekNewMentors + data.previousWeekNewDonors;
  }

  function MetricItem({title, children}) {
    return (<div>
        <p className="heading">{title}</p>
        <p className="title">{children}</p>
      </div>)
  }

  const onNewestUsersTableRowClick = (row) => {
    history.push(`/admin/users/${row.id}`);
  }

  return (
    <div className="admin-dashboard">
      <Heading>Dashboard</Heading>

      { isLoading ? <ProgressComponent /> :
        <>
          <Heading size="5">This Week</Heading>
          <Box>
            <Level>
              {/* <Level.Item className="has-text-centered">
                <MetricItem title="New Matches">
                  {data.lastWeekNewMatch} { plusMinusPercentSpan(data.lastWeekNewMatch, data.previousWeekNewMatch) }
                </MetricItem>
              </Level.Item> */}

              <Level.Item className="has-text-centered">
                <MetricItem title="New Mentors">
                  {data.lastWeekNewMentors} { plusMinusPercentSpan(data.lastWeekNewMentors, data.previousWeekNewMentors) }
                </MetricItem>
              </Level.Item>

              <Level.Item className="has-text-centered">
                <MetricItem title="New Mentees">
                  {data.lastWeekNewMentees} { plusMinusPercentSpan(data.lastWeekNewMentees, data.previousWeekNewMentees) }
                </MetricItem>
              </Level.Item>

              {/* <Level.Item className="has-text-centered">
                <MetricItem title="New Donors">
                  {data.lastWeekNewDonors} { plusMinusPercentSpan(data.lastWeekNewDonors, data.previousWeekNewDonors) }
                </MetricItem>
              </Level.Item> */}

              <Level.Item className="has-text-centered">
                <MetricItem title="Total New">
                  {sumNewUsers()} { plusMinusPercentSpan(sumNewUsers(), sumPrevUsers()) }
                </MetricItem>
              </Level.Item>
            </Level>
          </Box>

          <Heading size="5">Totals</Heading>
          <Box>
            <Level>
              <Level.Item className="has-text-centered">
                <MetricItem title="Mentors">
                  {data.mentorTotal}
                </MetricItem>
              </Level.Item>

              <Level.Item className="has-text-centered">
                <MetricItem title="Mentees">
                  {data.menteeTotal}
                </MetricItem>
              </Level.Item>

              {/* <Level.Item className="has-text-centered">
                <MetricItem title="Donors">
                  {data.donorTotal}
                </MetricItem>
              </Level.Item> */}

              <Level.Item className="has-text-centered">
                <MetricItem title="Total">
                  { data.mentorTotal + data.menteeTotal + data.donorTotal }
                </MetricItem>
              </Level.Item>
            </Level>
          </Box>

          <Heading className="mt-3" size="5">Newest Users</Heading>
          <Box>
            <TernTable hover={true} showTotal={false} columns={columns} data={tableData} onRowClick={onNewestUsersTableRowClick} />
          </Box>

        </>
      }
    </div>
  )
}

export default AdminDashboard;
