import { Heading, Card } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { isAfter, parseISO, format, isBefore } from 'date-fns';
import TodoItem from '../../../../components/TodoItem/TodoItem';
import { defaultDateString } from '../../../../lib/stringHelpers';

function Roadmap(props) {
  const profileTypeId = props.user?.activeProfile?.contactType?.id;

  let deadline = null;
  if (props.user?.group?.offboardingDate) {
    try {
      deadline = parseISO(props.user?.group?.offboardingDate);
    }
    catch { }
  }

  const PersonalProfileToDo = () => {
    if (props.user?.group?.onboardingDate) {
      const onboardingDate = parseISO(props.user?.group?.onboardingDate);
      if (isBefore(new Date(), onboardingDate)) {
        return <TodoItem title="Complete your personal profile" description={`Onboarding questions will be available on ${defaultDateString(onboardingDate)}.  Please check back then to complete your profile.`} status={'unavailable'} />
      }
    }

    if (props.hasOnboardingQuestions) {
      return <TodoItem title="Complete your personal profile" description="Let us get to know you better by filling out your personal profile." status={props.profileComplete ? 'completed' : 'unlocked'} linkTo='/onboarding' />
    }
    else {
      return <TodoItem title="Complete your personal profile" description="This cohort's onboarding questions are not available yet.  Please check back later to complete your profile." status={'unavailable'} />
    }
  }

  const OffboardingToDo = () => {
    const completeOffboarding = <TodoItem title="Complete the offboarding survey" description="Complete the final survey about your experience." status={props.user.offboardingCompletedOn ? 'completed' : 'unlocked'} onClick={props.onStartOffboarding} />;

    if (deadline) {
      const lockedOffboarding = <TodoItem title="Complete the offboarding survey" description={`Will be available ${format(deadline, 'PP')}`} status={'locked'} />

      if ((props.mentorshipCompletedOn && props.user.group.allowOffboardOnCompletion) || isAfter(new Date(), deadline) || props.offboardingAvailable) {
        return completeOffboarding;
      }
      else {
        return lockedOffboarding;
      }
    }
    else if (props.user.offboardingCompletedOn || props.offboardingAvailable) {
      return completeOffboarding;
    }

    return <></>
  }

  function GenPopMenteeRoapmapItems() {
    if (props.user.matchType === 'random') {
      return <>
        <PersonalProfileToDo />
        <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" status={props.user.profilePic ? 'completed' : 'unlocked'} onClick={props.onUploadProfile} />
      </>
    }
    else {
      return <>
        <PersonalProfileToDo />
        <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" status={props.user.profilePic ? 'completed' : 'unlocked'} onClick={props.onUploadProfile} />
        <TodoItem title="Complete the Info Sheet" description="Tell us about your background and experiences to find the best Mentor for you." status={props.infoSheetComplete ? 'completed' : 'unlocked'} linkTo="/infosheet" />
      </>
    }
  }

  function GenPopMentorRoapmapItems() {
    return <>
      <PersonalProfileToDo />
      <TodoItem title="Complete the Info Sheet" description="Tell us about your background and experiences to find the best Mentee for you." status={props.infoSheetComplete ? 'completed' : 'unlocked'} linkTo="/infosheet" />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" status={props.user.profilePic ? 'completed' : 'unlocked'} onClick={props.onUploadProfile} />
    </>
  }

  function CompanyMenteeRoapmapItems() {
    return <>
      <PersonalProfileToDo />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" status={props.user.profilePic ? 'completed' : 'unlocked'} onClick={props.onUploadProfile} />
      <OffboardingToDo />    
    </>
  }

  function CompanyMentorRoapmapItems() {
    return <>
      <PersonalProfileToDo />
      <TodoItem title="Upload your profile picture" description="Upload a picture of yourself" status={props.user.profilePic ? 'completed' : 'unlocked'} onClick={props.onUploadProfile} />
      <OffboardingToDo />    
    </>
  }

  function getRoadmapItems() {
    if (props.mentorshipCompletedOn || props.offboardingAvailable) {
      return <OffboardingToDo />
    }
    else {
      if (profileTypeId === 1) {
        // Mentee
        if (props.user.group.id === 1) return GenPopMenteeRoapmapItems();
        else return CompanyMenteeRoapmapItems();
      }
      else {
        // Mentor

        if (props.user.group.id === 1) return GenPopMentorRoapmapItems();
        else return CompanyMentorRoapmapItems();
      }
    }
  }

  return (
    <Card>
      <Card.Content>
        <div className="is-flex is-align-items-center">
          <div className="px-4">
            { props.mentorshipCompletedOn || props.offboardingAvailable ? 
              <FontAwesomeIcon className="has-text-info" size="3x" icon={faTrophy} />
            :
              <FontAwesomeIcon className="has-text-info" size="3x" icon={faRoad} />
            }
          </div>

          { props.offboardingCompletedOn ? 
            <div>
              <Heading>Congratulations {props.user.firstName}!</Heading>
              <Heading subtitle>
                You've completed your Mentorship journey!
              </Heading>
            </div>
          : props.offboardingAvailable ? 
            <div>
              <Heading>Congratulations {props.user.firstName}!</Heading>
              <Heading subtitle>
              Almost there! Just one thing remains to close out for this semester, the offboarding survey, which is critical for program enhancements and mentorship research. Thank you for completing this survey!
              </Heading>
            </div>
          :
            <div>
              <Heading>Welcome {props.user.firstName}!</Heading>
              <Heading subtitle>
                Follow this roadmap to get started!
              </Heading>
            </div>
          }

        </div>

        <hr/>
  
        { props.noItems ? 
          <div className="px-2 has-text-grey">Your roadmap is complete!</div>
        : 
          getRoadmapItems() 
        }
      </Card.Content>
    </Card>
  )

}

export default Roadmap;