import { toast } from 'react-toastify';
import { useState } from 'react';
import { Button, Form } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { groupAnswersReport, groupSurveyAnswersReport, groupOffboardingAnswersReport } from '../../lib/api';

function Reports({groupId, groupName}) {

  const [onlyMentorships, setOnlyMentorships] = useState(true);

  async function downloadReport(func, audience, filename) {
    //e.preventDefault();
    const reportData = await func(groupId, audience, onlyMentorships);

    if (reportData.byteLength === 0) {
      toast.warn('This report currently has no data');
      return;
    }

    const file = new Blob([reportData], {type: 'text/csv'});
    const data = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = data;
    link.download = `${groupName}_${audience}_${filename}`.replaceAll(' ', '_');

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

  }

  async function downloadOnboardingAnswersReport(audience) {
    return downloadReport(groupAnswersReport, audience, 'onboarding_answers.csv');
  }

  async function downloadSurveyAnswersReport(audience) {
    return downloadReport(groupSurveyAnswersReport, audience, 'session_answers.csv');
  }

  async function downloadOffboardingAnswersReport(audience) {
    return downloadReport(groupOffboardingAnswersReport, audience, 'offboarding_answers.csv');
  }

  const ReportCell = ({onDownload, title, description, children}) => {
    return (<>
      {/* <div className="is-flex">
        <div className="is-flex-grow-1"> */}
          <div className="has-text-weight-bold">{title}</div>
          <p>{description}</p>
          { children }
        {/* </div>
        <div> */}
          <Button.Group>
          <Button className="is-info" onClick={() => onDownload('mentor')}><FontAwesomeIcon icon={faDownload} /><span className="ml-3">Mentors</span></Button>
          <Button className="is-info" onClick={() => onDownload('mentee')}><FontAwesomeIcon icon={faDownload} /><span className="ml-3">Mentees</span></Button>
          </Button.Group>
        {/* </div>
      </div> */}
      </>)
  }
  
  return (
    <div>
      <div className="mb-4 card is-fullwidth">
        {/* <header className="card-header">
          <p className="card-header-title" >Session Surveys</p>
        </header> */}
        <div className="card-content">
          <div className="content">
            <ReportCell onDownload={downloadOnboardingAnswersReport} title="Onboarding Questions & Answers CSV" description="CSV file with names on the vertical, questions on the horizontal, and the answers in the intersections">
              <Form.Field style={{ marginTop: -7 }}>
                <Form.Checkbox checked={onlyMentorships} onChange={e => setOnlyMentorships(e.target.checked)}>Include only those in Mentorships</Form.Checkbox>
              </Form.Field>
            </ReportCell>
          </div>
        </div>
      </div>

      <div className="mb-4 card is-fullwidth">
        {/* <header className="card-header">
          <p className="card-header-title" >Session Surveys</p>
        </header> */}
        <div className="card-content">
          <div className="content">
            <ReportCell onDownload={downloadSurveyAnswersReport} title="Survey Answers CSV" description="CSV file with names on the vertical, survey questions and answers on the horizontal, and the answers in the intersections" />
          </div>
        </div>
      </div>

      <div className="mb-4 card is-fullwidth">
        {/* <header className="card-header">
          <p className="card-header-title" >Offboarding Q & A</p>
        </header> */}
        <div className="card-content">
          <div className="content">
            <ReportCell onDownload={downloadOffboardingAnswersReport} title="Offboarding Answers CSV" description="CSV file with names on the vertical, offboarding questions on the horizontal, and the answers in the intersections" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports;