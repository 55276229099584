import { useCallback } from 'react';
import { useHistory, NavLink, } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addGroup } from '../../../lib/api';
import GroupDetails from '../../../components/Group/GroupDetails';
import { getRandomSignupCode } from '../../../lib/stringHelpers';

function NewGroup(props) {
  const history = useHistory();

  const getDefaultData = useCallback(() => {
    return {
      id: null,
      name: 'Testifus',
      description: '',
      companyName: '',
      companyWebsite: '',
      enabled: false,
      signupCode: getRandomSignupCode(),
      bannerPic: '',
      mentorshipDuration: '12',
      color1: 'rgba(47,16,119,1)',
      color2: 'rgba(247,200,47,1)'
    }
  }, []);

  async function saveNewGroup(cohort) {
    const newGroupData = await addGroup(cohort);
    toast.success('Cohort created!');
    history.push(`/admin/cohorts/${newGroupData.id}`);
  }

  function cancelNewGroup() {
    history.push(`/admin/cohorts`);
  }

  return (
    <div className="container is-max-desktop">
      <div className="edit-group-page">
        <nav className="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><NavLink to="/admin/cohorts">Cohorts</NavLink></li>
            <li className="is-active"><a href="#">( New cohort )</a></li>
          </ul>
        </nav>
        <GroupDetails currentGroup={getDefaultData()} saveChanges={saveNewGroup} onCancel={cancelNewGroup} allowCancel={true} />
      </div>
    </div>
  )
}

export default NewGroup;