import { useState, useEffect } from 'react';
import { Heading, Box } from 'react-bulma-components';
import { toast } from 'react-toastify';

import { useRights } from '../../../lib/auth.js';
import { getUsers } from '../../../lib/api';

import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';
import UsersTable from '../../../components/UsersTable/UsersTable.js';
import AdminProfileDetailsModal from '../../../components/AdminProfileDetailsModal/AdminProfileDetailsModal.js';

function Users(props) {
  const { isAdmin } = useRights();
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [counts, setCounts] = useState({ total: 0, mentors: 0, mentees: 0 })
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);

  async function getAllUsers() {
    try {
      const data = await getUsers();
      setUsersData(data);
      if (data) {
        let newCounts = {
          total: data.length,
          mentors: data.filter(f => f.mentor_id).length,
          mentees: data.filter(f => f.mentee_id).length,
        }

        setCounts(newCounts);
      }
    }
    catch (err) {
      console.error('err', err)
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllUsers();
  }, [])

  function onUserCreated(userData) {
    setShowContactInfoModal(false);
    getAllUsers();
    toast.success('User created!');
  }

  return (
    <div className="admin-user-page">
      <Heading>Users</Heading>
      {isLoading ? <ProgressComponent /> : <>
        <Box>
          <nav className="level">
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Mentors</p>
                <p className="title">{counts.mentors}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Mentees</p>
                <p className="title">{counts.mentees}</p>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <p className="heading">Total</p>
                <p className="title">{counts.total}</p>
              </div>
            </div>
          </nav>
        </Box>
        <UsersTable usersData={usersData} onCreateUser={isAdmin ? () => setShowContactInfoModal(true) : null} />
      </>
      }

      {showContactInfoModal && <AdminProfileDetailsModal open={showContactInfoModal} onClose={() => setShowContactInfoModal(false)} onCancel={() => setShowContactInfoModal(false)} onSaved={(data) => onUserCreated(data)} isNewUser={true} />}
    </div>

  )
}

export default Users;
