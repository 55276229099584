import React, { useState } from 'react';
import { Form, Icon, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, } from '@fortawesome/free-solid-svg-icons'

function PasswordInput({password, setPassword, color}) {
  const [ visible, setVisible ] = useState(false);

  return <div className="field has-addons">
    <div className="control">
      <Form.Input color={color} type={ visible ? 'input' : 'password'} autoComplete="one-time-code" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

    <div className="control">
      <Button onClick={(e) => setVisible(!visible)}><Icon align="left"><FontAwesomeIcon icon={visible ? faEyeSlash : faEye } /></Icon></Button>
      </div>
</div>
}

export default PasswordInput;