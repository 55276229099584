import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, } from 'react-bulma-components';
import { ChromePicker } from 'react-color';

import { uploadBannerPic } from '../../lib/api';
import PictureUploaderModal from '../PictureUploaderModal/PictureUploaderModal';
import BannerPicture from '../BannerPicture/BannerPicture';

function BrandingSection({ currentGroup, saveChanges, cancelChanges }) {

  const getUnmodifiedSettings = useCallback(() => {
    let defaults = {};

    const settingsProps = ['color1', 'color2'];
    settingsProps.forEach(setting => defaults[setting] = currentGroup[setting]);

    return defaults;
  }, [currentGroup]);

  // const [formErrors, setFormErrors] = useState({});  
  const [showBannerDialog, setShowBannerDialog] = useState(false);
  const [modifiedData, setModifiedData] = useState(getUnmodifiedSettings());

  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [newBannerData, setNewBannerData] = useState(null);

  useEffect(() => {
    let hasChanges = JSON.stringify(modifiedData) !== JSON.stringify(getUnmodifiedSettings());
    hasChanges |= (newBannerData !== null);

    setIsModified(hasChanges);
  }, [modifiedData, newBannerData, getUnmodifiedSettings]);


  async function onBannerSelected(url) {
    setNewBannerData(url);
    setShowBannerDialog(false);
  }

  function updateProp(property, value) {
    setModifiedData(prev => ({ ...prev, [property]: value }))
    //clearError(property === 'name' ? 'companyName' : property);
  }

  async function saveModifications() {
    try {
      setIsSaving(true);

      if (newBannerData) {
        const response = await fetch(newBannerData);
        const buffer = await response.arrayBuffer();
        const file = new File([buffer], 'output.png', { type: 'image/png' });

        const formData = new FormData();
        formData.append('profilepic', file);
        await uploadBannerPic(currentGroup.id, formData);
      }

      await saveChanges(modifiedData);
    }
    catch (err) {
      console.error('Error saving branding', err);
    }
    finally {
      setIsSaving(false);
    }
  }
  
  function cancelModifications() {
    if (window.confirm('Cancel your changes?')) {
      setNewBannerData(null);
      setModifiedData(getUnmodifiedSettings());

      if (cancelChanges) {
        cancelChanges();
      }
    }
  }

  return <div className="cohort-branding">
    {currentGroup.id && <BannerPicture useEmptyBannerPic={currentGroup.id === 0} imageData={newBannerData} picture={currentGroup.bannerPic} color1={modifiedData.color1} color2={modifiedData.color2} onChangeClick={() => setShowBannerDialog(true)} />}

    <PictureUploaderModal format="landscape" groupId={currentGroup.id} show={showBannerDialog} onClose={() => setShowBannerDialog(false)} onCancel={() => setShowBannerDialog(false)} onSaved={onBannerSelected} />

    <div className="mt-6">
      {currentGroup.id &&
        <Form.Field className="is-grouped">
          <Form.Control>
            <Form.Label>Color 1</Form.Label>
            <ChromePicker color={modifiedData.color1} onChangeComplete={(color) => updateProp('color1', color.hex)} />
          </Form.Control>
          <Form.Control className="pl-4">
            <Form.Label>Color 2</Form.Label>
            <ChromePicker color={modifiedData.color2} onChangeComplete={(color) => updateProp('color2', color.hex)} />
          </Form.Control>
        </Form.Field>
      }
    </div>

    <div className="mt-3">
    <Button.Group>
        <Button disabled={!isModified || isSaving} className="is-success" loading={isSaving} onClick={saveModifications}>Save</Button>
        <Button disabled={isSaving || !isModified} className="is-outlined" onClick={cancelModifications}>Cancel</Button>
      </Button.Group>
    </div>
  </div>
}

export default BrandingSection;