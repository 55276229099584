import { useMemo, useState, useEffect } from 'react';
import { Form, Dropdown, Icon, Button } from 'react-bulma-components';
import { useHistory, useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { downloadFile } from '../../lib/download';
import { boolString, defaultDateString/*, defaultDateTimeString*/ } from '../../lib/stringHelpers';
import { userTypeString } from '../../lib/ternTypeHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faDownload, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useRights } from '../../lib/auth';
import TernTable from '../TernTable/TernTable';
import ProgressComponent from '../ProgressComponent/ProgressComponent';

function UsersTable({usersData, forCohort, isLoading, onTransferUser, onCreateUser, transferUserLoading}) {
  
  const history = useHistory();
  const location = useLocation();
  const { isAdmin } = useRights();
  const [tableData, setTableData] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState(getDefaultSelectedUserTypes());
  const [userFilter, setUserFilter] = useState("");
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    setUserTypes([
      { id: 1, description: 'Mentee'},
      { id: 2, description: 'Mentor'},
      // { id: 3, description: 'Donor'},
      { id: 4, description: 'Admin'},
      { id: 5, description: 'Cohort Leader'},
    ].filter(f => isAdmin || (!isAdmin && f.id !== 4 && f.id !== 5)));
  }, [isAdmin]);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set('usertypes', JSON.stringify(selectedUserTypes).replaceAll(/[[\]]+/g, ''));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }, [history, location.pathname, location.search, selectedUserTypes]);

  const onTableRowClick = (row) => {
    history.push(`/admin/users/${row.id}`);
  }

  function getDefaultSelectedUserTypes() {
    let userTypesFilter = [1, 2, 4, 5];
    const search = new URLSearchParams(location.search);
    if (search.get('usertypes')) {
      try {
        userTypesFilter = JSON.parse(`[${search.get('usertypes')}]`);
      }
      catch { }
    }
    return userTypesFilter;
  }

  function getAvailableString(user) {
    let availString = '-';
    if (user.active_profile === 1 || user.active_profile === 2) {
      availString = user.active_profile === 1 ? boolString(user.mentee_available) : boolString(user.mentor_available);
    }

    return availString;
  }

  function getStatusBadge(user) {
    let statusColor = user.match_id ? 'is-success' : 'is-info';
    let statusString = user.match_id ? 'Matched' : 'Unmatched';
    if (statusString) {
      return <span className={`tag mr-2 ${statusColor}`}>{statusString}</span>
    }
  }

  function getStatusString(user) {
    return user.match_id ? 'Matched' : 'Unmatched';
  }

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  useEffect(() => {
    let theData = usersData.filter(user => selectedUserTypes.includes(user.active_profile));
    if (userFilter) {
      const userFilterLC = userFilter.toLowerCase();
      theData = theData.filter(user => user.last_name.toLowerCase().includes(userFilterLC) || user.first_name.toLowerCase().includes(userFilterLC) || user.email.toLowerCase().includes(userFilterLC));
    }

    setTableData(theData.map((user) => {
      return {
        id: user.cognito_id,
        last_name: user.last_name,
        first_name: user.first_name,
        profiletype: userTypeString(user.active_profile),
        // enabled: boolString(user.enabled),
        status: getStatusString(user),
        statusBadge: getStatusBadge(user),
        available: getAvailableString(user),
        email: user.email,
        group_name: (user.active_profile === 4 || user.active_profile === 5)  ? '-' : user.group_name,
        creation_date: parseISO(user.creation_date),
        profile_complete: boolString(user.profile_complete),
        ready: boolString(user.match_ready_at !== null)
      }
    }));
  }, [usersData, selectedUserTypes, userFilter])

  function updateSelectedUserTypes(userTypeId, include) {
    if (include) {
      setSelectedUserTypes([...selectedUserTypes, userTypeId]);
    }
    else {
      setSelectedUserTypes(selectedUserTypes.filter(id => id !== userTypeId));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Type',
        accessor: 'profiletype',
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: 'Cohort',
        accessor: 'group_name',
        show: !forCohort,
      },
      {
        Header: <span>Onboarding<br/>Completed</span>,
        accessor: 'profile_complete',
        collapse: true,
      },
      // {
      //   Header: 'Ready',
      //   accessor: 'ready',
      //   collapse: true,
      // },
      {
        Header: 'Available',
        accessor: 'available',
        collapse: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        collapse: true,
        Cell: props => props.row.original.statusBadge
      },
      // {
      //   Header: 'Enabled',
      //   accessor: 'enabled',
      //   collapse: true,
      // },
      {
        Header: 'Member Since',
        accessor: 'creation_date',
        sortType: 'datetime',
        Cell: props => defaultDateString(props.value)
      },
    ],
    [forCohort]
  )

  function csvDownload() {
    let csvData = '"Last name","First name","Type","Onboarding Complete","Available","Status","Member Since"';
    if (!forCohort) {
      csvData += ',"Cohort"';
    }
    csvData += '\n';

    tableData.forEach(row => {
      csvData += `"${row.last_name}","${row.first_name}","${row.profiletype}","${row.profile_complete}","${row.ready}","${row.status}","${defaultDateString(row.creation_date)}"`;
      if (!forCohort) {
        csvData += `,"${row.group_name}"`;
      }
      csvData += '\n';
    })
    downloadFile(csvData, 'users.csv');
  }

  return (<>
      {isLoading ? <ProgressComponent /> :
        <>
          <div className="is-flex is-justify-content-space-between mb-3">
            <div className="is-flex" style={{ gap: '1.5rem' }}>
              <Form.Field className="has-addons" style={{ maxWidth: '75%' }}>
                <Form.Control>
                  <Form.Input placeholder="Search..." value={userFilter} onChange={(e) => setUserFilter(e.target.value)} />
                  <Icon align="left"><FontAwesomeIcon icon={faSearch} /></Icon>
                </Form.Control>
                <Form.Control>
                  <Button disabled={userFilter === ''} onClick={e => setUserFilter('')}>
                    <Icon align="right"><FontAwesomeIcon icon={faTimesCircle} /></Icon>
                  </Button>
                </Form.Control>
              </Form.Field>
              <Dropdown
                right={true}
                closeOnSelect={false}
                color=""
                icon={<FontAwesomeIcon icon={faAngleDown} className="ml-3" />}
                label="User types"
                value=""
              >
                {userTypes.map((userType) =>
                  <Dropdown.Item key={`dropdownitem_usertype_input_${userType.id}`} renderAs="div">
                    <div>
                      <label className="checkbox">
                        <input id={`usertype_input_${userType.id}`}
                          onChange={e => updateSelectedUserTypes(userType.id, e.target.checked)}
                          type="checkbox"
                          name={`usertype_input_${userType.id}`}
                          checked={selectedUserTypes.includes(userType.id)} className="mr-2" />
                        {userType.description}
                      </label>
                    </div>
                  </Dropdown.Item>
                )}

              </Dropdown>
            </div>
            <Button.Group>
              { onCreateUser && <button className="button is-info is-outlined" onClick={() => onCreateUser()}>Create User</button> }
              { onTransferUser && <Button className="is-success" loading={transferUserLoading} onClick={() => onTransferUser()}>Transfer User</Button> }
              <Button className="is-info is-outlined" onClick={csvDownload}><span>Export</span><span className="icon is-small"><FontAwesomeIcon icon={faDownload} /></span></Button>
            </Button.Group>

          </div>
          <TernTable hover={true} showTotal={false} columns={columns} data={data} initialSort={[{ id: 'last_name', desc: false }]} onRowClick={onTableRowClick} />
        </>
      }
    </>
  )
}

export default UsersTable;
