import React, {forwardRef} from 'react';
import { Form, Icon } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';

const CustomDateInput = forwardRef(({ value, onClick, isSuccess, onFocus, color }, ref) => {
  
  let colorClassName = '';
  if (color) colorClassName = `is-${color}`;
  return (
    <Form.Control>
      <input className={`input ${colorClassName}`} type="text" readOnly onFocus={(e) => { if (onFocus) onFocus(); onClick(e); }} placeholder="Select date" value={value} ref={ref} />
      <Icon align="left">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </Icon>
    </Form.Control>
  )})
;

export default CustomDateInput;