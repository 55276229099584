export function downloadFile(fileData, filename, mimeType) {
  const theMimeType = mimeType || 'text/csv';

  const file = new Blob([fileData], {type: theMimeType});
  const data = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = data;
  link.download = filename;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);

}