import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faGlobe, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { formatPhone } from '../../lib/stringHelpers.js';
import { Button } from 'react-bulma-components';

function ContactInfo(props) {
  const emailLink = `mailto:${props.profile?.email}`;
  const phoneLink = `tel:${props.profile?.phoneNumber}`;

  function purifiedLink(baseUrl, userData) {
    if (baseUrl && userData) {
      return `${baseUrl}${userData.match(/@*(.*)/)[1]}`;
    }

    return '';
  }

  function copyEmailToClipboard() {
    navigator.clipboard.writeText(props.profile.email);
    toast.success(`Email copied to clipboard`);
    return false;
  }

  return (
    <div className=" is-inline-block has-text-left">
      <div className="is-flex is-align-items-center">
        <a href={emailLink}>
          <div className="social-media-item">
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="social-media-text">{props.profile.email}</span>
          </div>
        </a>
        { props.showCopyEmail && <Button className="ml-3 is-small" title="Copy Email" onClick={(e) => { copyEmailToClipboard(); e.stopPropagation(); }}><FontAwesomeIcon style={{fontSize: 'small'}} icon={faCopy}/></Button> }
      </div>

    {props.profile.phoneNumber && 
      <a href={phoneLink}>
        <div className="social-media-item">
          <FontAwesomeIcon icon={faPhone} />
          <span className="social-media-text">{formatPhone(props.profile.phoneNumber)}</span>
        </div>
      </a>
    }

    {props.profile.twitter &&
      <a href={purifiedLink('https://twitter.com/', props.profile.twitter)} target="_blank" rel="noreferrer">
        <div className="social-media-item">
          <FontAwesomeIcon icon={faTwitter} />
          <span className="social-media-text">{props.profile.twitter}</span>
        </div>
      </a>
    }

    {props.profile.instagram &&
      <a href={purifiedLink('https://instagram.com/', props.profile.instagram)} target="_blank" rel="noreferrer">
        <div className="social-media-item">
          <FontAwesomeIcon icon={faInstagram} />
          <span className="social-media-text">{props.profile.instagram}</span>
        </div>
      </a>
    }

    {props.profile.workWebsite &&
      <a href={props.profile.workWebsite} target="_blank" rel="noreferrer">
        <div className="social-media-item">
          <FontAwesomeIcon icon={faGlobe} />
          <span className="social-media-text">Work Website</span>
        </div>
      </a>
    }

    {props.profile.personalWebsite &&
      <a href={props.profile.personalWebsite} target="_blank" rel="noreferrer">
        <div className="social-media-item">
          <FontAwesomeIcon icon={faGlobe} />
          <span className="social-media-text">Personal Website</span>
        </div>
      </a>
    }
    </div>
  )
}

export default ContactInfo;