import { useEffect, useState } from 'react';

import { Columns, Form } from 'react-bulma-components';
import { useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { userUI as userSchema } from '../../schemas';
import { getGroups } from '../../lib/api';
import { registerFormComponents, controlColor } from '../../lib/formHelpers';
import PasswordInput from '../PasswordInput/PasswordInput';

function AdminContactInfoForm({ user, doSubmit, setDoSubmit, handleOnSubmitResult, isNewUser, formErrors }) {
  const [userType, setUserType] = useState('cohortAdmin');
  const [groups, setGroups] = useState([]);
  const [password, setPassword] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedCohortError, setSelectedCohortError] = useState();

  const formParams = {
    resolver: joiResolver(userSchema),
    defaultValues: { ...user },
  }

  useEffect(() => {
    getGroups()
      .then((data) => {
        data.sort((a,b) => a.name.localeCompare(b.name));
        setGroups(data);
      })
      .catch(() => {
        console.error('Error retrieving groups');
      })
  }, []);

  // Don't do this check here, do it in the route
  // Otherwise it'll run during onboarding and prematurely redirect out of the wizard
  // useEffect(() => {
  //   if (user) {
  //     history.push('/');
  //   }
  // }, []) 

  const { register, handleSubmit, formState: { errors } } = useForm(formParams);
  const controlledInput = registerFormComponents(register, errors);

  useEffect(() => {

    if (doSubmit) {
      setDoSubmit(false);
      console.log('validate');

      if (handleOnSubmitResult) {
        let birthdateValid = true;  // Default to true for new users

        handleSubmit((data) => {
          if (isNewUser && userType === 'admin' && !selectedGroup) {
            setSelectedCohortError('Required');
            handleOnSubmitResult({ valid: false, errors: [] });
            return;
          }
          else {
            setSelectedCohortError('');
          }
          
          const newData = {
            valid: birthdateValid,
            data: {
              ...data,
              userType,
              password,
              groupId: selectedGroup,
            }
          }

          handleOnSubmitResult(newData);
        },
          (err) => {
            handleOnSubmitResult({ valid: false, errors: err });
          })();
      }
    }
  }, [doSubmit, handleSubmit, handleOnSubmitResult, selectedGroup, userType, isNewUser]);

  return (<>
    <Columns>
      {/* First Name */}
      <Columns.Column>
        <Form.Field>
          <Form.Label>First name</Form.Label>
          {controlledInput('firstName')}
        </Form.Field>
      </Columns.Column>

      {/* Last Name */}
      <Columns.Column>
        <Form.Field>
          <Form.Label>Last name</Form.Label>
          {controlledInput('lastName')}
        </Form.Field>
      </Columns.Column>
    </Columns>

    {/* Email */}
    <Form.Field>
      <Form.Label>Email</Form.Label>
      {controlledInput('email', 'input', { includeHelp: false, disabled: !isNewUser, autoComplete: 'one-time-code' })}
      {formErrors.email ? <Form.Help color={"danger"}>{formErrors.email}</Form.Help> 
        : <Form.Help color={controlColor(errors, 'email')}>{errors?.email?.message ? errors?.email?.message : 'This is used for login'}</Form.Help>
      }
      
      </Form.Field>

    {isNewUser && (
      <>
        {/* Password field */}
        <Form.Field>
          <Form.Label>Password</Form.Label>
          <PasswordInput password={password} setPassword={setPassword} />
          <Form.Help color={"danger"}>{formErrors.password}</Form.Help>
        </Form.Field>

        <Columns>
          <Columns.Column className="is-one-third">
            <Form.Field>
              <Form.Label>User Type</Form.Label>
              <Form.Control>
                <Form.Select className="is-fullwidth" value={userType} onChange={(e) => setUserType(e.target.value)}>
                  <option value="cohortAdmin">Cohort Leader</option>
                  <option value="admin">Admin</option>
                  <option value="mentor">Mentor</option>
                  <option value="mentee">Mentee</option>
                </Form.Select>
              </Form.Control>
            </Form.Field>
          </Columns.Column>

          <Columns.Column className={`is-two-thirds ${(userType === 'admin' || userType === 'cohortAdmin') ? 'is-hidden' : ''}`}>
            <Form.Field>
              <Form.Label>Cohort</Form.Label>
              <Form.Control>
                <Form.Select color={selectedCohortError ? 'danger' : ''} className="is-fullwidth" value={selectedGroup.toString()} onChange={(e) => { setSelectedCohortError(''); setSelectedGroup(parseInt(e.target.value)); }}>
                  <option value="0">Select cohort...</option>
                  {groups.map(group => <option key={`group_${group.id}`} value={group.id}>{group.name}</option>)}
                </Form.Select>
                <Form.Help color="danger">{selectedCohortError}</Form.Help>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </>
    )}
  </>
  );
}

export default AdminContactInfoForm;