import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

import AdminDashboard from '../screens/Admin/AdminDashboard/AdminDashboard';
import PendingMatches from '../screens/Admin/Matches/Matches';
import PromoCodes from '../screens/Admin/PromoCodes/PromoCodes';
import Users from '../screens/Admin/Users/Users';
import User from '../screens/Admin/Users/User';
import Groups from '../screens/Admin/Groups/Groups';
import Group from '../screens/Admin/Groups/Group';
import Metrics from '../screens/Admin/Metrics/Metrics';
import Referrals from '../screens/Admin/Referrals/Referrals';
import Mentorships from '../screens/Admin/Mentorships/Mentorships';
import Mentorship from '../screens/Admin/Mentorship/Mentorship';
import Notifications from '../screens/Admin/Notifications/Notifications';
import NewGroup from '../screens/Admin/Groups/NewGroup';

function AdminRoutes() {
  const { path, } = useRouteMatch();

  return (
    <div className="container is-widescreen" style={{ paddingTop: '3.25rem' }}>
      <Switch>
        <Route path={`${path}/dashboard`}>
          <AdminDashboard />
        </Route>

        <Route exact path={`${path}`}>
          <Redirect to="/admin/dashboard" replace />
        </Route>

        {/* <Route exact path={`${path}/matching`}>
              <Matching />
            </Route> */}

        <Route exact path={`${path}/pendingmatches`}>
          <PendingMatches />
        </Route>

        <Route exact path={`${path}/notifications`}>
          <Notifications />
        </Route>

        <Route exact path={`${path}/mentorships`}>
          <Mentorships />
        </Route>

        <Route exact path={`${path}/mentorships/:mentorshipId`}>
          <Mentorship />
        </Route>

        <Route exact path={`${path}/promocodes`}>
          <PromoCodes />
        </Route>

        <Route exact path={`${path}/users`}>
          <Users />
        </Route>

        <Route exact path={`${path}/metrics`}>
          <Metrics />
        </Route>

        <Route exact path={`${path}/referrals`}>
          <Referrals />
        </Route>

        <Route exact path={`${path}/cohorts`}>
          <Groups />
        </Route>

        <Route exact path="/admin/cohorts/new">
          <NewGroup />
        </Route>

        <Route path={`${path}/cohorts/:groupId`}>
          <Group />
        </Route>

        <Route exact path={`${path}/users/:userId`}>
          <User />
        </Route>

      </Switch>
    </div>
  )
}

export default AdminRoutes;