import logo from '../assets/logo-horizontal-white.png';
import { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useStore } from '../store/Store';
import { useRights, getNonProdName } from '../lib/auth';
import FeedbackModal from './FeedbackModal/FeedbackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'

function MainNavNoDropdown({ children }) {
  const { state } = useStore();
  const { isAdmin, isCohortAdmin } = useRights();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  const navStyle = getNonProdName() ? { backgroundColor: 'orange' } : {};

  const AdminTopNavBar = () => {
    return (<div id="topNavBar" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
      <div className="navbar-start">
        <NavLink className="navbar-item" to="/admin/dashboard" exact={true} activeClassName="is-active">Dashboard</NavLink>
        <NavLink className="navbar-item" to="/admin/users" exact={true} activeClassName="is-active">All Users</NavLink>
        <NavLink className="navbar-item" to="/admin/cohorts" activeClassName="is-active">Cohorts</NavLink>
        <NavLink className="navbar-item" to="/admin/mentorships" exact={true} activeClassName="is-active">Mentorships</NavLink>
        <NavLink className="navbar-item" to="/admin/notifications" exact={true} activeClassName="is-active">Notifications</NavLink>
        <NavLink className="navbar-item" to="/admin/metrics" exact={true} activeClassName="is-active">Metrics</NavLink>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <Link to="/signout" className="button is-light">
              Sign Out
            </Link>
          </div>
        </div>
      </div>
    </div>)
  }

  const UserTopNavBar = () => {
    return (<div id="topNavBar" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
      <div className="navbar-start">
        {!state.user &&
          <Link to="/" className="navbar-item">
            Dashboard
          </Link>
        }
        {state.user &&
          <Link to="/profile" className="navbar-item">
            Profile
          </Link>
        }
      </div>

      <div className="navbar-end">
        <a href="/" className="navbar-item is-hidden-mobile" onClick={(e) => { setFeedbackModalOpen(true); e.preventDefault(); }} title="Send feedback">
          <FontAwesomeIcon icon={faCommentAlt} style={{ fontSize: 22 }} />
        </a>

        <a href="/" className="navbar-item is-hidden-tablet" onClick={(e) => { setFeedbackModalOpen(true); e.preventDefault(); }} title="Send feedback">Send Feedback</a>

        <div className="navbar-item">
          <div className="buttons">
            <Link to="/signout" className="button is-light">
              Sign Out
            </Link>
          </div>
        </div>
      </div>
    </div>)
  }

  const CohortAdminTopNavBar = () => {
    return (
      <div id="topNavBar" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-start">
          <NavLink className="navbar-item" to="/admin/dashboard" exact={true} activeClassName="is-active">Dashboard</NavLink>
          <NavLink className="navbar-item" to="/admin/cohorts" activeClassName="is-active">Cohorts</NavLink>
        </div>
  
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link to="/signout" className="button is-light">
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }  

  return (
    <div style={{ paddingTop: 32 }}>
      <nav className="navbar is-fixed-top" style={navStyle} role="navigation" aria-label="main navigation">
        <div className="container is-widescreen">
          <div className="navbar-brand">
            <a className="navbar-item" href="https://www.ternmentoring.com">
              <img src={logo} alt="TERN Mentoring Logo" style={{ paddingLeft: 20, paddingRight: 20 }} />
              {getNonProdName() && <span className="is-uppercase has-text-weight-bold">{getNonProdName()} Server</span>}
            </a>

            <a onClick={() => setisActive(!isActive)} role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="topNavBar">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          {isAdmin ? <AdminTopNavBar /> : isCohortAdmin ? <CohortAdminTopNavBar /> : <UserTopNavBar />}

        </div>
      </nav>
      <FeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)} />
      {children}
    </div>
  )
}

export default MainNavNoDropdown;