import './TernTable.scss';

import { useTable, useSortBy } from 'react-table';
import classNames from 'classnames';

function TernTable(props) {

  let tableInstanceProps = { columns: props.columns, data: props.data,
    sortTypes: {
      alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
              return rowOneColumn.toUpperCase() >
                  rowTwoColumn.toUpperCase()
                  ? 1
                  : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
      }
    },
    initialState: {
      hiddenColumns: props.columns.filter(col => col.show === false).map(col => col.accessor),
    }
    
  };

  if (props.initialSort) {
    tableInstanceProps = {
      ...tableInstanceProps,
      initialState: { 
        ...tableInstanceProps.initialState,
        sortBy: props.initialSort
      },
    }
  }

  const tableInstance = useTable(tableInstanceProps,
    useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <div>
      { props.showTotal && <b>Total: {props.data.length}</b> }
      <table className={`table tern-table is-fullwidth ${props.hover ? ' is-hoverable' : ''}`} {...getTableProps()}>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th {...column.getHeaderProps({
                  className: classNames({collapse: column.collapse, datecolumn: column.dateColumn, mediumcolumn: column.mediumcolumn}),
                    ...column.getSortByToggleProps()})}>
                  {// Render the header
                  column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)

              return (
                // Apply the row props
                <tr {...row.getRowProps({ className: classNames({rowDisabled: row.original.rowDisabled})})} onClick={(props.onRowClick && !row.original.rowDisabled) ? () => props.onRowClick(row.original) : null}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps({
                        className: classNames({rowDisabled: row.original.rowDisabled, collapse: cell.column.collapse, datecolumn: cell.column.dateColumn, mediumcolumn: cell.column.mediumcolumn}),
                      })}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default TernTable;
