import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Icon, Heading, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, getHours, addMinutes, parse } from 'date-fns';
import { useStore } from '../../../store/Store';
import { scheduleSession, setSessionSurvey, getSession, getMatched } from '../../../lib/api';
import CustomDateInput from '../../CustomDateInput/CustomDateInput';
import SurveyQuestionComponent from '../../SurveyQuestionComponent/SurveyQuestionComponent';
import Linkify from '../../Linkify/Linkify';

const SessionComponent = forwardRef((props, ref) => {
  const { formValidChange, isCompleting, isMentor, completionStateChanged, groupId } = props;

  const surveySource = isMentor ? 'mentor_survey' : 'mentee_survey';
  const matcheeNoun = isMentor ? 'Mentee' : 'Mentor';
  // const sessionDate = props.session?.session_date && parseISO(props.session.session_date);

  const { state, dispatch } = useStore();
  const [newSessionDate, setNewSessionDate] = useState(props.session.session_date ? parseISO(props.session.session_date) : null);
  const [formattedTime, setFormattedTime] = useState(props.session.session_date ? format(parseISO(props.session.session_date), 'hh:mm a') : '08:00 AM');
  const [sessionWasScheduled, setSessionWasScheduled] = useState(false);
  const [sessionErrorText, setSessionErrorText] = useState('');
  const [sendInvite, setSendInvite] = useState(true);
  const [notes, setNotes] = useState(props.session?.notes ? props.session.notes : '');

  const [surveyAnswers, setSurveyAnswers] = useState(initialAnswers());
  const [comments, setComments] = useState(props.session[surveySource]?.comments);

  function initialAnswers() {
    // Pull the questions from the state
    if (state?.matched?.survey_questions) {
      const questions = JSON.parse(JSON.stringify(state.matched.survey_questions.quick_survey));

      // Sort the questions by ordernum
      questions.sort((a,b) => a.orderNum - b.orderNum);

      // Map the answers, if any
      questions.forEach(question => {
        question.uiType = getQuestionType(question.values);
        if (props.session[surveySource] && props.session[surveySource].survey_answers) {
          const answer = props.session[surveySource].survey_answers.find(f => f.id === question.id);
          if (answer) {
            question.answer = answer.answer;
          }
        }
      })

      return questions;
    }

    return [];
  }

  function getQuestionType(options) {
    if (options.includes('{"yes')) return 'bool';
    if (options.includes('{"1')) return 'rating';
    return 'text';
  }

  function updateAnswer(id, value) {
    setSurveyAnswers((prev) => {
      const newAnswers = [ ...prev ];
      const existingAnswer = newAnswers.find(f => f.id === id);
      if (existingAnswer) existingAnswer.answer = value;

      return newAnswers;
    })
  }

  useImperativeHandle(ref, () => ({
    onDoneClicked() {
      return onDone();
    },
  }));

  useEffect(() => {
    if (formValidChange) {
      if (!isCompleting)
        return true;
      else {
        formValidChange(surveyAnswers.filter(f => !f.answer && f.uiType === 'rating').length === 0);
      }
    }
  }, [formValidChange, surveyAnswers, isCompleting])

  function onCompletionStateChanged(newState) {
    if (completionStateChanged) {
      completionStateChanged(newState);
    }

    if (formValidChange) {
      formValidChange(false);
    }
  }

  function getDescription(session) {
    return (
      <ul className="mb-3">
        {session.description.split('\n').map((line, lineno) => <li key={`session_${session.id}_${lineno}`}><Linkify>{line}</Linkify></li>)}
      </ul>
    )
  }

  function getTimeOptions() {
    let startTime = new Date(2022, 1, 1, 6, 0, 0);
    let options = [];

    while (getHours(startTime) !== 22) {
      let timeSlot = format(startTime, 'hh:mm a');
      options.push(<option key={timeSlot}>{timeSlot}</option>);
      startTime = addMinutes(startTime, 15);
    }

    return options;
  }

  function hasDateChanged() {
    const theSessionTime = parse(formattedTime, 'hh:mm a', new Date(newSessionDate));
    return (!props.session.session_date || (parseISO(props.session.session_date).getTime() !== theSessionTime.getTime()));
  }

  async function onSchedule() {
    let success = false;
    if (!newSessionDate) {
      setSessionErrorText('Please provide a meeting date');
    }
    else {
      let theSessionTime = parse(formattedTime, 'hh:mm a', new Date(newSessionDate));
      const errorText = props.onCheckSessionTime(props.session, theSessionTime);
      
      setSessionErrorText(errorText);
      
      if (!errorText) {
        try {
          await scheduleSession(props.session.id, theSessionTime.toISOString(), sendInvite, notes, dispatch);
          success = true;
          setSessionWasScheduled(true);
        }
        catch (err) {
          console.log('err')
        }
      }
    }

    return success;
  }

  async function onDone() {
    let success = false;
    if (props.isCompleting) {
      try {
        const surveyData = {
          surveyAnswers,
          comments,
        }

        const result = await setSessionSurvey(props.session.id, surveyData, dispatch);
        await getSession(props.session.id, dispatch);

        if (result.mentorshipNowComplete) {
          const matchedWithData = await getMatched();
          dispatch( { type: 'SET_MATCHED_WITH', payload: matchedWithData });
        }
        success = true;
      }
      catch (err) {
        console.error(err);
      }
    }
    else {
      if (hasDateChanged() || notes != props.session?.notes) {
        // Update the notes
        success = await onSchedule();
      }
      else {
        success = true;
      }
    }

    return success;
  }

  function surveySection() {
    const questions = isMentor ? props.session.mentor_survey : props.session.mentee_survey;
    const alreadyCompleted = questions !== null;

    return (<>

      { surveyAnswers.map(answer => 
          <SurveyQuestionComponent key={`survey_question_${answer.id}`} alreadyCompleted={alreadyCompleted} question={answer} updateAnswer={updateAnswer} />
        )
      }

      { groupId === 1 && 
        <Form.Field>
          <Form.Label>Comments to your { matcheeNoun }</Form.Label>
          <Form.Control>
            { alreadyCompleted ? 
              (questions.comments ? 
                  <div>{ questions.comments }</div>
                :
                  <span className="explanationText has-text-grey-light">No comments provided</span>
              )
              :
                <textarea onChange={(e) => setComments(e.target.value)} className="textarea mb-2" placeholder="Enter you comments here..." value={comments} />
            }
          </Form.Control>
        </Form.Field>
      }
    </>

    )
  }

  function getMentorView() {
    return (
      <>
        { (props.session.completed && 
            <div className="has-text-success has-text-weight-semibold">
              You completed this session with your Mentee on {format(parseISO(props.session.session_date), 'PPPPpp')}
            </div>)
        }

        { (props.session.completed && props.session.mentor_survey && 
          <>
            <label className="label">Here were your survey answers:</label>
            { surveySection() }
          </>)
        }

        { (props.isCompleting && 
          <>
            <label className="label">Please complete the below survey:</label>
            { surveySection() }
          </>)
        }

        {!props.session.completed && !props.isCompleting &&
          <>
            <Form.Field style={{ width: '9.2rem', display: 'inline-block' }}>
              <Form.Label>Date</Form.Label>
              <DatePicker
                popperPlacement="bottom" selected={newSessionDate}
                onChange={(date) => setNewSessionDate(date)}
                customInput={<CustomDateInput />}
              />
            </Form.Field>

            <Form.Field style={{ marginLeft: 12, width: '10rem', display: 'inline-block' }}>
              <Form.Label>Time (ET)</Form.Label>
              <Form.Control>
                <Form.Select className={sessionWasScheduled && 'is-success'} value={formattedTime} onFocus={() => { setSessionErrorText('') }} onChange={e => setFormattedTime(e.target.value)}>
                  {getTimeOptions()}
                </Form.Select>
                <Icon align="left">
                  <FontAwesomeIcon icon={faClock} />
                </Icon>
              </Form.Control>
            </Form.Field>

            { sessionErrorText && 
              <div className="explanationText has-text-danger has-text-weight-semibold">
                { sessionErrorText }
              </div>
            }

            <Form.Field>
              <Form.Label>Meeting link or notes</Form.Label>
              <Form.Textarea rows={2} placeholder="Copy your meeting link or any other notes here..." value={notes} onChange={e => setNotes(e.target.value)} />
            </Form.Field>

            <Form.Field style={{ marginTop: -7 }}>
              <Form.Checkbox checked={sendInvite} onChange={e => setSendInvite(e.target.checked)}>Email a calendar invite</Form.Checkbox>
            </Form.Field>

            {sessionWasScheduled &&
              <div className="explanationText has-text-success has-text-weight-semibold">
                Your meeting has been scheduled
              </div>
            }

            <div className="explanationText">
              Meetings are expected to be at least 30 minutes.  {sendInvite && '  You and your Mentee will each receive a calendar invite in your email.  '}
              Once you've completed the session be sure to come back here and fill out the session survey.
            </div>
          </>
        }

      </>
    )
  }

  function getMenteeView() {
    return (<>
            { (props.session.completed && 
            <div className="has-text-success has-text-weight-semibold">
              You completed this session with your Mentor on {format(parseISO(props.session.session_date), 'PPPPpp')}
            </div>)
        }

        { (props.session.completed && props.session.mentee_survey && 
          <>
            <label className="label">Here were your survey answers:</label>
            { surveySection() }
          </>)
        }

        { (props.isCompleting && 
          <>
            <label className="label">Please complete the below survey:</label>
            { surveySection() }
          </>)
        }

      { !props.session.completed && !props.isCompleting && 
        <>
          <Form.Field style={{width: '25%', display: 'inline-block'}}>
            <Form.Label>Date</Form.Label>
            <DatePicker 
              popperPlacement="right" selected={newSessionDate} 
              onChange={(date) => setNewSessionDate(date)} 
              customInput={<CustomDateInput />} 
            />
          </Form.Field>

          <Form.Field style={{marginLeft: 12, width: '27%', display: 'inline-block'}}>
            <Form.Label>Time (ET)</Form.Label>
            <Form.Control>
              <Form.Select className={sessionWasScheduled && 'is-success'} value={formattedTime} onFocus={() => { setSessionErrorText('') }} onChange={e => setFormattedTime(e.target.value)}>
                { getTimeOptions() }
              </Form.Select>
              <Icon align="left">
                  <FontAwesomeIcon icon={faClock} />
              </Icon>
            </Form.Control>
          </Form.Field>

          { sessionErrorText && 
            <div style={{marginTop: -9, marginBottom: 7}} className="pt-0 explanationText has-text-danger has-text-weight-semibold">
              { sessionErrorText }
            </div>
          }

          <Form.Field>
              <Form.Label>Meeting link or notes</Form.Label>
              <Form.Textarea rows={2} placeholder="Copy your meeting link or any other notes here..." value={notes} onChange={e => setNotes(e.target.value)} />
            </Form.Field>

          <Form.Field style={{marginTop: -7}}>
            <Form.Checkbox checked={sendInvite} onChange={e => setSendInvite(e.target.checked)}>Email a calendar invite</Form.Checkbox>
          </Form.Field>
          
          { sessionWasScheduled && 
            <div className="explanationText has-text-success has-text-weight-semibold">
              Your meeting has been scheduled
            </div>
          }

          <div className="explanationText">
            Meetings are expected to be at least 30 minutes.  { sendInvite && '  You and your Mentor will each receive a calendar invite in your email.  '}  
            Once you've completed the session be sure to come back here and fill out the session survey.
          </div>
        </>
      }
    </>);
  }

  return (
    <>
      {props.showTitle !== undefined && props.showTitle !== false &&
        <Heading>{props.session.title}</Heading>
      }

      {props.isCompleting && 
        <p className="mb-3" style={{fontSize: 'small'}}>On a 5 star scale (5 being the highest/yes rating and 1 being the lowest/no rating), select the number of stars that best describes your mentoring session today. Since mentoring is unique to the individuals involved, there are no expectations that you will experience any or all these things. Our goal is to evaluate the value created by the mentoring relationships as they progress. This information will not be shared with your { isMentor ? 'Mentee' : 'Mentor'}.</p>
      }

      { getDescription(props.session) }

      { isMentor ? getMentorView() : getMenteeView() }

    </>
    );
})


export default SessionComponent;