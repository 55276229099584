import { useState, useEffect } from 'react';

export function useFormValidation(validator) {
  const [formErrors, setFormErrors] = useState({});
  const [hasFormErrors, setHasFormErrors] = useState(false);

  useEffect(() => {
    setHasFormErrors(Object.keys(formErrors).length === 0);
  }, [formErrors]);

  function validateForm() {
    setFormErrors({});
    if (validator) {
      validator();
      return Object.keys(formErrors).length === 0;
    }
    else {
      console.error('No validator provided to validateForm hook');
    }
  }

  function addValidationError(key, message) {
    const newErrors = { ...formErrors };
    newErrors[key] = message;
    setFormErrors(newErrors);
  }

  function addRequiredError(key) {
    addValidationError(key, 'Required');
  }

  function addAlreadyExistsError(key, message) {
    const finalMsg = message || 'Already exists';
    addValidationError(key, finalMsg);
  }

  function clearError(key) {
    let newErrorObj = { ...formErrors };
    delete newErrorObj[key];
    setFormErrors(newErrorObj);
  }

  return {
    formErrors,
    hasFormErrors,
    validateForm,
    addValidationError,
    addRequiredError,
    addAlreadyExistsError,
    clearError,
  };
}
