import { useEffect, useState, useMemo } from 'react';
import { Form, Heading, Container, Button } from 'react-bulma-components';
import { arrayToCsvCapitalized, notProvidedString, locationString, defaultDateString, defaultDateTimeString } from '../../lib/stringHelpers.js';
import { Link } from 'react-router-dom';
import { alphaSort99 } from '../../lib/formHelpers';
import { getUserActivity, getCohortAdminGroups, getGroups, addCohortAdminToGroup, removeCohortAdminFromGroup } from '../../lib/api';
import { parseISO } from 'date-fns';
import { useRights } from '../../lib/auth';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture.js';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import TernTable from '../../components/TernTable/TernTable';
import OnboardingProfile from '../../components/OnboardingProfile/OnboardingProfile.js';
import CreateProfileDetailsModal from '../../components/CreateProfileDetails/CreateProfileDetailsModal.js';
import { toast } from 'react-toastify';
import CohortsTable from '../../components/CohortsTable/CohortsTable.js';

function UserProfile(props) {
  const { isAdmin } = useRights();

  const [activityData, setActivityData] = useState([]);
  const [tableDataForActivity, setTableData] = useState([]);
  const [viewingTab, setViewingTab] = useState('profile');
  const [showContactInfoModal, setShowContactInfoModal] = useState(false);
  const [cohortData, setCohortData] = useState([]); // New state for cohort data
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);
  const [allCohorts, setAllCohorts] = useState([]);
  const [profileData, setProfileData] = useState(props.profile);
  const [userTypeId, setUserTypeId] = useState(props.profile?.activeProfile.contactType.id);
  const [rightsInfo, setRightsInfo] = useState({ isAdminType: false, isAdmin: false, isCohortAdmin: false, });
  const [selectedCohort, setSelectedCohort] = useState(0);

  useEffect(() => {
    const newProfileData = { ...props.profile };
    setProfileData(newProfileData);
    getTheActivity(newProfileData.contactId);

    const userTypeId = newProfileData.activeProfile.contactType.id;

    if (userTypeId === 5) {
      fetchCohortData(newProfileData.contactId);
    }

    setUserTypeId(userTypeId);

    const newRightsInfo = {
      isAdminType: (userTypeId === 4 || userTypeId === 5),
      isAdmin: (userTypeId === 4),
      isCohortAdmin: (userTypeId === 5),
    }

    setRightsInfo(newRightsInfo);

    setViewingTab(newRightsInfo.isAdminType ? newRightsInfo.isCohortAdmin ? 'cohorts' : 'activity' : 'profile');

  }, [props.profile]);

  useEffect(() => {
    async function fetchGroups() {
      const groups = await getGroups();
      groups.sort((a,b) => a.name.localeCompare(b.name));
      setAllCohorts(groups);
    }

    fetchGroups();
  }, []);

  async function getTheActivity(contactId) {
    const res = await getUserActivity(contactId);
    setActivityData(res);
  }

  const activityTableData = useMemo(
    () => tableDataForActivity,
    [tableDataForActivity]
  )

  const fetchCohortData = async (contactId) => {
    setIsLoadingGroups(true);
    try {
      const res = await getCohortAdminGroups(contactId);
      setCohortData(res);
    } catch (error) {
      console.error('Error fetching cohort data:', error);
    } finally {
      setIsLoadingGroups(false);
    }
  };

  useEffect(() => {
    setTableData(activityData.filter(f => f.event_name !== 'session created').map((item) => {
      return {
        timestamp: parseISO(item.event_date),
        eventname: item.event_name,
        eventdata: getDataText(item),
      }
    }));
  }, [activityData])

  const activityTablecolumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timestamp',
        sortType: 'datetime',
        Cell: props => defaultDateTimeString(props.value)
      },
      {
        Header: 'Activity',
        accessor: 'eventname',
        Cell: props => <span className="is-capitalized">{props.value}</span>
      },
      {
        Header: 'Data',
        accessor: 'eventdata',
      },
    ],
    []
  );

  const handleDeleteCohortClick = async (rowData) => {
    if (window.confirm('Are you sure you want to remove this cohort from this Cohort Leader?')) {
      try {
        const cohortAdminId = props.profile.contactId;
        await removeCohortAdminFromGroup(cohortAdminId, rowData.id);
        toast.success('Cohort removed successfully');

        // Re-fetch the cohort data to refresh the table
        fetchCohortData(cohortAdminId);
      } catch (err) {
        toast.error('Failed to remove cohort');
        console.error('Failed to remove cohort', err);
      }
    }
  }

  const handleAddCohortClick = async (groupId) => {
    try {
      const cohortAdminId = props.profile.contactId;
      await addCohortAdminToGroup(cohortAdminId, groupId);
      toast.success('Cohort added successfully');

      // Re-fetch the cohort data to refresh the table
      fetchCohortData(cohortAdminId);
    } catch (err) {
      toast.error('Failed to add cohort');
      console.error('Failed to add cohort', err);
    }
  };

  function getDataText(data) {
    let textToReturn = '';

    if (data.event_name === 'session created') {
      if (data.event_data_3) {
        return `Session ${data.event_json.session_num} scheduled for ${defaultDateString(parseISO(data.event_data_3))}`;
      }
      else {
        return `Session ${data.event_json.session_num} (Not scheduled yet)`;
      }
    }
    else if (data.event_name === 'survey completed') {
      return `Session ${data.event_json.session_num} survey completed`;
    }
    else if (data.event_name === 'email sent') {
      return `${data.event_data}`;
    }

    return textToReturn;
  }

  function onEditUserSave(updatedProfile) {
    setShowContactInfoModal(false);
    setProfileData(updatedProfile);
  }

  return (
    <Container className="is-fullwidth">
      <div className="is-flex is-justify-content-space-between" style={{ width: '100%' }}>
        <div>
          <Heading>
            {profileData.firstName} {profileData.lastName}
          </Heading>
          <Heading subtitle className="mb-1" style={{ textTransform: 'capitalize' }}>{profileData.activeProfile.contactType.description} {profileData.preferredPronouns && `(${profileData.preferredPronouns})`}</Heading>

          {!rightsInfo.isAdminType &&
            <Heading subtitle style={{ textTransform: 'capitalize', marginBottom: '0.25rem'}}><Link to={`/admin/cohorts/${profileData.group.id}?tab=users`}>{profileData.group.name}</Link></Heading>
          }

          <Heading subtitle style={{ textTransform: 'capitalize', marginBottom: '0.25rem'}}>Created on: {defaultDateString(profileData.creationDate)}</Heading>

          <div style={{ lineHeight: '1.25em', marginTop: '0.75rem' }}>
            <ContactInfo profile={profileData} showCopyEmail={true} />
          </div>
          { isAdmin && <button className="button is-info is-outlined" onClick={() => setShowContactInfoModal(true)}>Change User Info</button> }
        </div>
        <div className="" style={{ textAlign: 'right', height: 250, width: 250 }}>
          <ProfilePicture picture={profileData.profilePic} />
        </div>
      </div>

      <div className="tabs is-medium">
        <ul>
          {!rightsInfo.isAdminType && <li className={`${viewingTab === 'profile' ? ' is-active' : ''}`} onClick={() => setViewingTab('profile')}><a>Profile</a></li>}
          {rightsInfo.isCohortAdmin && <li className={`${viewingTab === 'cohorts' ? ' is-active' : ''}`} onClick={() => setViewingTab('cohorts')}><a>Cohorts</a></li>}
          <li className={`${viewingTab === 'activity' ? ' is-active' : ''}`} onClick={() => setViewingTab('activity')}><a>Activity</a></li>
        </ul>
      </div>

      {/* Profile */}
      {viewingTab === 'profile' && props.profile.groupId === 1 && (
        <>
          <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap">

            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Personal Details</Heading>
              <ul>
                <li><label>Birthdate:</label>{defaultDateString(props.profile.birthDate)}</li>
                <li><label>ZIP code:</label>{notProvidedString(props.profile.zipCode)}</li>
                {userTypeId === 1 && (
                  <li><label>Identifies As:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.identities.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherRace, props.profile.infoSheet.otherGender))}</li>
                )}
                {userTypeId === 1 && (
                  <li><label>Languages:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.languages.map(e => e.description).sort(alphaSort99)))}</li>
                )}
              </ul>
            </div>

            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Location</Heading>
              <ul>
                {userTypeId === 2 && <li><label>Company:</label>{notProvidedString(props.profile.infoSheet?.workCompany)}</li>}
                {/* {userTypeId === 2 && <li><label>Roles:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.roleTypes.map(e => e.description).sort(alphaSort99)))}</li>} */}
                {userTypeId === 2 && <li><label>Work Location:</label>{notProvidedString(locationString(props.profile.infoSheet?.workCity, props.profile.infoSheet?.workState, props.profile.infoSheet?.workCountry))}</li>}
                <li><label>Location:</label>{notProvidedString(locationString(props.profile.infoSheet?.currentCity, props.profile.infoSheet?.currentState, props.profile.infoSheet?.currentCountry))}</li>
                <li><label>Hometown:</label>{notProvidedString(locationString(props.profile.infoSheet?.homeTown, props.profile.infoSheet?.hometownState, props.profile.infoSheet?.hometownCountry))}</li>
              </ul>
            </div>

            <div className="info-block constrain-info-block">
              <Heading size={4} className="has-text-grey">Education</Heading>
              <ul>
                {userTypeId === 1 && <li><label>University:</label>{props.profile.education.university}</li>}
                {userTypeId === 2 && <li><label>Alma Mater:</label>{props.profile.education.university}</li>}
                {(userTypeId === 1 || userTypeId === 2) && (
                  <>
                    <li><label>Graduation Date:</label>{notProvidedString(defaultDateString(props.profile.education.graduationDate))}</li>
                    <li><label>Major Degrees:</label>{arrayToCsvCapitalized(props.profile.education.majorDegrees)}</li>
                    <li><label>Minor Degrees:</label>{notProvidedString(props.profile.education.minorDegrees)}</li>
                    <li><label>Advanced Degrees:</label>{notProvidedString(props.profile.education.advancedDegrees)}</li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div className="info-block">
            <Heading size={4} className="has-text-grey">Interests</Heading>
            <ul>
              {(userTypeId === 1 || userTypeId === 2) && (
                <>
                  <li><label>Personal Interests:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.personalInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherPersonalInterest))}</li>
                  <li><label>Business Interests:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.businessInterests.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherBusinessInterest))}</li>
                  <li><label>Affinity Groups:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.infoSheet.affinityGroups.map(e => e.description).sort(alphaSort99), props.profile.infoSheet.otherAffinityGroup))}</li>
                </>
              )}
            </ul>
          </div>

          <div className="info-block">
            <Heading size={4} className="has-text-grey">Statements</Heading>
            <ul>
              {userTypeId === 2 && <li><label>Mentor Roles:</label>{notProvidedString(arrayToCsvCapitalized(props.profile.mentorRoles.map(e => e.description).sort(alphaSort99)))}</li>}
              <li><label>Goals Statement:</label>{notProvidedString(props.profile.infoSheet?.goalsStatement)}</li>
              {userTypeId !== 2 && <li><label>Formative Experience:</label>{notProvidedString(props.profile.infoSheet?.formativeExperience)}</li>}
              {userTypeId !== 2 && <li><label>Career Aspiration:</label>{notProvidedString(props.profile.infoSheet?.careerInspiration)}</li>}
              {userTypeId !== 2 && <li><label>Strengths:</label>{notProvidedString(props.profile.infoSheet?.strength)}</li>}
              {userTypeId === 2 && <li><label>Advice:</label>{notProvidedString(props.profile.infoSheet?.reflectiveAdvice)}</li>}
              <li><label>Would Like To:</label>{notProvidedString(props.profile.infoSheet?.toImprove)}</li>
              <li><label>Additional Info:</label>{notProvidedString(props.profile.infoSheet?.additionalInfo)}</li>
              {(userTypeId === 1 || userTypeId === 2) && (
                <li><label>Recommendations:</label>{(props.profile.infoSheet.recommendations && props.profile.infoSheet.recommendations.length > 0) ? props.profile.infoSheet.recommendations.map((m, idx) => <div key={`recommendation_${idx}`}>{m}</div>) : 'None provided'}</li>
              )}
            </ul>
          </div>

        </>
      )}
      {viewingTab === 'profile' && props.profile.groupId !== 1 && (
        <OnboardingProfile contactId={props.profile.contactId} groupId={props.profile.groupId} />
      )}

      {/* Activity */}
      {viewingTab === 'activity' &&
        <div>
          <TernTable columns={activityTablecolumns} data={activityTableData} />
        </div>
      }

      {/* Cohorts */}
      {viewingTab === 'cohorts' && (
        <div>
          <div className="is-flex">
            <Form.Field>
              <Form.Control>
                <Form.Select value={selectedCohort.toString()} onChange={(e) => setSelectedCohort(parseInt(e.target.value))}>
                  <option value="0">Select cohort...</option>
                  {allCohorts.map(group => <option key={`group_${group.id}`} value={group.id}>{group.name}</option>)}
                </Form.Select>
              </Form.Control>
            </Form.Field>
            <Button className="ml-3 is-info" onClick={() => handleAddCohortClick(selectedCohort)} >Add Cohort</Button>
          </div>

          {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                  <button className="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>Add Cohort</span>
                    <span className="icon is-small">
                      <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    {groupNames.map((name, index) => (
                      <a
                        key={index}
                        href="#"
                        className="dropdown-item"
                        onClick={() => handleAddCohortClick(groupIds[index])}
                      >
                        {name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                  <button className="button is-danger" aria-haspopup="true" aria-controls="delete-dropdown-menu">
                    <span>Delete Cohort</span>
                    <span className="icon is-small">
                      <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="delete-dropdown-menu" role="menu">
                  <div className="dropdown-content">
                    {cohortData.map((cohort, index) => (
                      <a
                        key={index}
                        href="#"
                        className="dropdown-item"
                        onClick={() => handleDeleteCohortClick(cohort.id)}
                      >
                        {cohort.companyName}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {isLoadingGroups ? (
            <p>Loading...</p>
          ) : (
            <CohortsTable cohorts={cohortData} deleteTitle="Remove" onDeleteClick={handleDeleteCohortClick} />
          )}
        </div>
      )}

      {/* User Edit */}
      <CreateProfileDetailsModal user={profileData} userTypeId={userTypeId} open={showContactInfoModal} onClose={() => setShowContactInfoModal(false)} onCancel={() => setShowContactInfoModal(false)} onSaved={onEditUserSave} isNewUser={false} />
    </Container>
  );
}

export default UserProfile;