import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Redirect } from 'react-router-dom';
import { useStore } from '../../store/Store';
import { getUserQuestionsAndAnswers, setUserQuestionAnswers, patchUser } from '../../lib/api';

import OnboardingWizardItem from '../../components/OnboardingWizardItem/OnboardingWizardItem';
import AnswerComponent from '../../components/AnswerComponent/AnswerComponent';
import ProgressComponent from '../../components/ProgressComponent/ProgressComponent';

function CompanyOnboarding() {
  const history = useHistory();
  const { state, dispatch } = useStore();

  const isMentor = state?.user?.activeProfile?.contactType?.id === 2;
  const id = isMentor ? state?.user.activeProfile.mentorId : state?.user.activeProfile.menteeId;

  const [ isLoading, setIsLoading ] = useState(true);
  const [ pageNum, setPageNum ] = useState(0);
  const [ missingAnswers, setMissingAnswers ] = useState([]);
  const [ newAnswers, setNewAnswers ] = useState({});
  const [ questionsAndAnswers, setQuestionsAndAnswers ] = useState([]);

  // Get initial data
  useEffect(() => {

    getUserQuestionsAndAnswers(id, isMentor)
      .then((data) => {
        setIsLoading(true);
        let pages = [];

        if (data) {
          data.forEach(question => {
            if (question.pageId) {
              let group = pages.find(f => f.pageId === question.pageId);
              if (!group) {
                group = { pageId: question.pageId, pageOrder: question.pageOrder, pageTitle: question.pageTitle, pageHeading: question.pageHeading, pageSubheading: question.pageSubheading, questions: [], params: question.pageParams};
                pages.push(group);
              }
              group.questions.push(question);
            }
          });
          console.log('pages', pages)
        }
        const orderedPages = pages.sort((a, b) => a.pageOrder - b.pageOrder)
        setQuestionsAndAnswers(orderedPages);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      })
    }, [pageNum, id, isMentor]);

  // Set the answers
  useEffect(() => {
    if (questionsAndAnswers.length > 0) {
      let answers = {};
      questionsAndAnswers[pageNum].questions.forEach(theQuestion => {
        let theAnswerValue = theQuestion.answer_value;
        if (theQuestion.answerType === 'multiselect') {
          if (theAnswerValue) {
            try 
            {
              theAnswerValue = JSON.parse(theAnswerValue);
            }
            catch (err) {
              theAnswerValue = [];
            }
          }
        }
        else if (theQuestion.answerType === 'integer') {
          if (theAnswerValue === undefined || theAnswerValue === null) {
            theAnswerValue = theQuestion.min;
          }
        }
  
        answers[theQuestion.questionId] = theAnswerValue;
      })
      setNewAnswers(answers);
      setMissingAnswers([]);
    }
  }, [pageNum, questionsAndAnswers])

  async function commitAnswer() {
    return new Promise((resolve, reject) => {
      // const theQuestion = questionsAndAnswers[questionNum];
      // let theAnswerToCommit = currentAnswer;
      // if (currentAnswer instanceof Array) {
      //   theAnswerToCommit = JSON.stringify(currentAnswer);
      // }
      
      // Check we have all required answers
      let newMissingAnswers = [];
      questionsAndAnswers[pageNum].questions.forEach(question => {
        if (!question.optional && newAnswers[question.questionId] === null) {
          newMissingAnswers.push(question.questionId);
        }
      })
      console.log('missingAnswers', newMissingAnswers);
      
      if (newMissingAnswers.length === 0) {
        setUserQuestionAnswers(id, isMentor, newAnswers)
          .then((res) => {
            // theQuestion.answer_value = currentAnswer;
            resolve();
          })
          .catch(err => {
            toast.error("Your answers could not be saved.  Please try again");
            console.error('commitAnswer ', err);
            reject();
          })
      }
      else {
        setMissingAnswers(newMissingAnswers);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        reject(new Error('Missing answers'));
      }
    })
  }

  function progress () { 
    const now = pageNum + 1; 
    const totalPages = questionsAndAnswers.length;
    return (
      <div className="mb-5 container is-max-desktop">
        <div className="questionnaire-progress-bar-wrapper">
          <ProgressComponent value={now} label={`Page ${now} of ${totalPages}`} max = {totalPages} />
        </div>
      </div>
    )
  }

  async function finish() {
    try {
      await commitAnswer();

      const updatedData = {
        wizard1Page: 99,
        wizard2Page: 99,
        wizard3Page: 99,
      }

      await patchUser(isMentor, state.user, updatedData, dispatch);

      history.push('/dashboard');
    }
    catch (err) {
      console.error(err)
    }
  }

  async function gotoNextQuestion() {
    try {
      await commitAnswer();
      if (pageNum < questionsAndAnswers.length) setPageNum(pageNum+1);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    catch (err) {
      if (err.message?.toLowerCase() !== 'missing answers') {
        console.error(err);
      }
    }
  }

  async function gotoPrevQuestion() {
    try {
      // await commitAnswer();
      if (pageNum > 0) setPageNum(pageNum-1);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    catch (err) {
      if (err.message?.toLowerCase() !== 'missing answers') {
        console.error(err);
      }
    }
  }

  function updateAnswer(questionId, val) {
    newAnswers[questionId] = val;
    setNewAnswers({...newAnswers});

    setMissingAnswers(missingAnswers.filter(f => f !== questionId));
  }

  function renderQuestion() {
    const canGoBack = questionsAndAnswers.length > 1 && pageNum > 0;
    const canGoNext = questionsAndAnswers.length > 1 && pageNum < questionsAndAnswers.length - 1;
    const isHorizontal = questionsAndAnswers[pageNum]?.params?.layout === 'horizontal';
    const showTitle = questionsAndAnswers[pageNum]?.params?.showPageTitle || false;

    return (
        <OnboardingWizardItem title={showTitle ? questionsAndAnswers[pageNum].pageTitle : ''} description={questionsAndAnswers[pageNum].pageHeading} canGoBack={canGoBack} canGoNext={canGoNext} onNext={gotoNextQuestion} onBack={gotoPrevQuestion} onFinish={finish}>
          { missingAnswers.length > 0 && <div className="has-text-danger has-text-centered has-text-weight-bold">One or more questions below are required to be answered before proceeding</div> }
          { questionsAndAnswers[pageNum].questions.map(question => <div className={isHorizontal ? 'my-2' : 'my-5'} key={`page_${pageNum}_${question.questionId}`} >
            { questionsAndAnswers[pageNum]?.params?.layout === 'horizontal' ?
              <div className="is-flex is-align-items-center">
                <div style={{width: '40%', paddingRight: 7}}>
                  { missingAnswers.includes(question.questionId) && <div className="has-text-danger has-text-weight-bold">An answer is required here</div>}
                  <div className="has-text-weight-semibold">{ question.description }</div>
                </div>
                <div style={{width: '60%'}}>
                  <AnswerComponent question={question} questionId={question.questionId} updateAnswer={updateAnswer} answer={newAnswers[question.questionId]} required={!question.optional} horizontal={isHorizontal}/>
                </div>
              </div>
            :
              <>
                { missingAnswers.includes(question.questionId) && <div className="has-text-danger has-text-weight-bold">An answer is required here</div>}
                <div className="has-text-weight-semibold">{ question.description }</div>
                <AnswerComponent question={question} questionId={question.questionId} updateAnswer={updateAnswer} answer={newAnswers[question.questionId]} required={!question.optional} horizontal={isHorizontal}/>
              </>
            }
          </div>)}
        </OnboardingWizardItem>
    )
  }
  return (<>
    <div className="is-hidden-mobile is-fullheight onboarding-screen-background" />

    <div className="questionnaire">
      { isLoading ? 
        <div className="container is-max-desktop">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', zIndex: 100 }}>
            <div style={{width: '33%'}}><ProgressComponent /></div>
          </div>
        </div>
      :
        <div className="container is-max-desktop">
          { progress() }

          { (questionsAndAnswers && questionsAndAnswers.length > 0) ? 
            renderQuestion()
            :
              <Redirect to="/" />
          }
        </div>
      }
    </div>
  </>
  );
}

export default CompanyOnboarding;