import { useMemo, useState, useEffect } from 'react';
import { Heading } from 'react-bulma-components';
import { Link } from "react-router-dom";
import { getPendingMatches } from '../../../lib/api';
import { defaultDateTimeString } from '../../../lib/stringHelpers';
import TernTable from '../../../components/TernTable/TernTable';
import ProgressComponent from '../../../components/ProgressComponent/ProgressComponent';

function PendingMatches(props) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);
  
  useEffect(() => {
    getPendingMatches().then((data) => {
      console.log('data', data);
      setTableData([])
      setTableData(data.map((match) => { 
        return {
          id: match.id,
          mentee: <Link to={`/admin/users/${match.mentee_contact_id}`}>{`${match.mentee_last_name}, ${match.mentee_first_name}`}</Link>,
          mentor: <Link to={`/admin/users/${match.mentor_contact_id}`}>{`${match.mentor_last_name}, ${match.mentor_first_name}`}</Link>,
          matchScorePercent: <span style={{fontWeight: 'bold'}}>{((match.match_score / match.criteria_total) * 100).toFixed(0)}%</span>,
          status: paidTag(match.paid),
          matched_on: defaultDateTimeString(match.matched_date),
        }
      }));
    })
    .catch((err) => {
      console.error('err', err)
    })
    .finally(() => setIsLoading(false));
  }, [])

  function paidTag(paid) {
    const classes = `tag mr-2 ${paid ? 'is-success' : 'is-warning'}`;
    const text = `${paid ? 'Paid' : 'Unpaid'}`;

    return <span className={classes}>{text}</span>
  }

  const data = useMemo(
    () => tableData,
    [tableData]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Mentee',
        accessor: 'mentee',
      },
      {
        Header: 'Mentor',
        accessor: 'mentor',
      },
      {
        Header: 'Score',
        accessor: 'matchScorePercent',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Matched On',
        accessor: 'matched_on',
      },
    ],
    []
  )

return (
  <>
    { isLoading ? <ProgressComponent /> :
      <div className="admin-matching-page">
        <Heading>Pending Matches</Heading>

        <TernTable showTotal={true} columns={columns} data={data} />

    </div>}
  </>)

}

export default PendingMatches;
