import Amplify from 'aws-amplify';
import { useStore } from '../store/Store';

const isProd = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV !== 'staging';

Amplify.configure({
  Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // Amazon Cognito User Pool ID
      userPoolId: isProd ? 'us-east-1_tMxVpvmHT'
        : 'us-east-1_dsWzOWXhy', 
      
        
      // Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: isProd ? 'olh13iqjppl6guk7vteclfpn6' 
        : '2oppdgm210jh78d70qq73a4qn1',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: '.elysiumsoftware.com',
      // // OPTIONAL - Cookie path
      //     path: '/',
      // // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //     sameSite: "lax",
      // // OPTIONAL - Cookie secure flag
      // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //     secure: true
      // },

      // OPTIONAL - customized storage object
      //storage: MyStorage,
      
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      //authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      clientMetadata: { myCustomKey: 'myCustomValue' },

       // OPTIONAL - Hosted UI configuration
      // oauth: {
      //     domain: 'your_cognito_domain',
      //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      //     redirectSignIn: 'http://localhost:3000/',
      //     redirectSignOut: 'http://localhost:3000/',
      //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // }
  }
});

// TODO: Get this from the user store instead
export function useRights() {
  const { state } = useStore();

  const isAdmin = state?.user?.activeProfile?.contactType.id === 4;
  const isCohortAdmin = state?.user?.activeProfile?.contactType.id === 5;

  return { isAdmin, isCohortAdmin };
}

export function getNonProdName() {
  if (process.env.REACT_APP_ENV !== 'production') {
    return process.env.REACT_APP_ENV;
  }
  return '';
}