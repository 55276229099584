import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, } from 'react-bulma-components';
import DatePicker from 'react-datepicker';
import QRCode from "qrcode.react";
import { parseISO, differenceInWeeks, isAfter } from "date-fns";
import { useRights } from '../../lib/auth';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRedo } from '@fortawesome/free-solid-svg-icons'
import { useFormValidation } from "../../hooks/useFormValidation";
import CustomDateInput from "../CustomDateInput/CustomDateInput";
import { isUrl, currentHost, getRandomSignupCode } from "../../lib/stringHelpers";

function GroupDetails({ currentGroup, saveChanges, onCancel, allowCancel }) {

  const getUnmodifiedSettings = useCallback(() => {
    let defaults = {};

    const settingsProps = ['signupCode', 'description', 'companyName', 'companyWebsite', 'mentorshipDuration', 'allowOffboardOnCompletion', 'onboardingDate', 'offboardingDate'];
    settingsProps.forEach(setting => defaults[setting] = currentGroup[setting]);

    return defaults;
  }, [currentGroup]);

  const { isCohortAdmin } = useRights();
  const { formErrors, addValidationError, addRequiredError, addAlreadyExistsError, clearError, validateForm } = useFormValidation(formValidator);
  const [signupUrl, setSignupUrl] = useState('');
  const [modifiedSettings, setModifiedSettings] = useState(getUnmodifiedSettings());
  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let defaults = {};

    const settingsProps = ['signupCode', 'description', 'companyName', 'companyWebsite', 'mentorshipDuration', 'allowOffboardOnCompletion', 'onboardingDate', 'offboardingDate'];
    if (currentGroup) {
      settingsProps.forEach(setting => defaults[setting] = currentGroup[setting]);
    }

    setModifiedSettings(defaults);
  }, [currentGroup]);

  useEffect(() => {
    let hasChanges = JSON.stringify(modifiedSettings) !== JSON.stringify(getUnmodifiedSettings());
    setIsModified(hasChanges);
  }, [modifiedSettings, getUnmodifiedSettings])

  useEffect(() => {
    setSignupUrl(`${currentHost()}signup?signupcode=${modifiedSettings.signupCode}`)
  }, [modifiedSettings.signupCode])

  //useEffect(() => console.log('formErrors', formErrors), [formErrors]);

  async function saveModifications(newSettings) {
    if (validateForm()) {
      try {
        setIsSaving(true);
        await saveChanges(newSettings);
      }
      catch (err) {
        let errData = err?.response?.data;
        if (errData?.errors) {
          errData.errors.forEach(error => {
            if (error.type === 'alreadyExists') {
              if (error.context.key === 'name') {
                addAlreadyExistsError('companyName');
              }
              else {
                addAlreadyExistsError(error.context.key);
              }
            }
          })
        }
        console.error('saveModifications', err);
      }
      finally {
        setIsSaving(false);
      }
    }
  }

  function cancelModifications() {
    if (window.confirm('Cancel your changes?') === true) {
      setModifiedSettings(getUnmodifiedSettings());

      if (onCancel) {
        onCancel();
      }
    }
  }

  function formValidator() {
    if (modifiedSettings.companyWebsite && !isUrl(modifiedSettings.companyWebsite, true)) {
      addValidationError('companyWebsite', 'Improperly formatted URL');
    }

    if (!(parseFloat(modifiedSettings.signupCode) >= 100000 && parseFloat(modifiedSettings.signupCode) <= 999999)) {
      addValidationError('signupCode', 'Code must be six digits');
    }

    if (!modifiedSettings.companyName || !modifiedSettings.companyName.trim()) {
      addRequiredError('companyName');
    }

    if (!modifiedSettings.mentorshipDuration || isNaN(parseInt(modifiedSettings.mentorshipDuration))) {
      addRequiredError('mentorshipDuration');
    }

    // if (!modifiedSettings.onboardingDate) {
    //   addRequiredError('onboardingDate');
    // }

    if (modifiedSettings.offboardingDate) {
      const badDate = isAfter(parseISO(modifiedSettings.onboardingDate), parseISO(modifiedSettings.offboardingDate))
      if (badDate) {
        addValidationError('offboardingDate', 'Invalid offboarding date');
      }
      else {
        if (modifiedSettings.onboardingDate && modifiedSettings.offboardingDate && differenceInWeeks(parseISO(modifiedSettings.offboardingDate), parseISO(modifiedSettings.onboardingDate)) < parseInt(modifiedSettings.mentorshipDuration)) {
          addValidationError('mentorshipDuration', 'Duration too short');
        }
      }
    }
  }

  function updateCurrentGroupProp(property, value) {
    setModifiedSettings(prev => ({ ...prev, [property]: value }))
    clearError(property === 'name' ? 'companyName' : property);
  }

  function changeGroupEnabledState(newState) {
    if (!newState || (newState && validateForm())) {
    if (window.confirm(`Do you want to really want to ${newState ? 'enable' : 'disable'} access to the public for this group?`)) {
      const newGroupState = { ...modifiedSettings, enabled: newState };
      saveModifications(newGroupState);
      }
    }
  }

  const downloadQR = () => {
    const canvas = document.getElementById("signupQRCode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${modifiedSettings.companyName.toLowerCase().replaceAll(/ /g, '-')}-qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function askAndGenerateNewSignupCode() {
    if (window.confirm('Are you sure you want to generate a new signup code for this group?  Once a new signup code is create, the old one will no longer work.')) {
      updateCurrentGroupProp('signupCode', getRandomSignupCode());
      toast.success('New signup code generated');
    }
  }

  return <div className="cohort-details">

    <div className="mt-3">
      <Form.Field className="is-grouped">
        <Form.Control className="is-expanded">
          <Form.Label>Cohort name</Form.Label><Form.Input disabled={isCohortAdmin} color={formErrors.companyName ? 'danger' : ''} value={modifiedSettings.companyName} onChange={e => { updateCurrentGroupProp('companyName', e.target.value); updateCurrentGroupProp('name', e.target.value) }}></Form.Input>
          {formErrors.companyName && <Form.Help color="danger">{formErrors.companyName}</Form.Help>}
        </Form.Control>
        <Form.Control className="is-expanded">
          <Form.Label>Website (optional)</Form.Label><Form.Input disabled={isCohortAdmin} color={formErrors.companyWebsite ? 'danger' : ''} value={modifiedSettings.companyWebsite} onChange={e => updateCurrentGroupProp('companyWebsite', e.target.value)}></Form.Input>
          {formErrors.companyWebsite && <Form.Help color="danger">{formErrors.companyWebsite}</Form.Help>}
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Description<span style={{ color: '#999', fontSize: 'x-small', paddingLeft: 7 }}>(internal only - will not be displayed to users)</span></Form.Label>
        <Form.Input disabled={isCohortAdmin} value={modifiedSettings.description} onChange={e => updateCurrentGroupProp('description', e.target.value)}></Form.Input>
      </Form.Field>

      <div className="is-flex" style={{ gap: '1.5rem' }}>

        <Form.Field style={{ width: '25%', display: 'inline-block' }}>
          <Form.Label>Onboarding Date</Form.Label>
          <DatePicker disabled={isCohortAdmin} 
            popperPlacement="right" selected={modifiedSettings.onboardingDate ? parseISO(modifiedSettings.onboardingDate) : null}
            onChange={(date) => updateCurrentGroupProp('onboardingDate', date.toISOString())}
            customInput={<CustomDateInput color={formErrors.onboardingDate ? 'danger' : ''} onFocus={() => console.log('onboardingDate focus')} />}
          />
          {formErrors.onboardingDate && <Form.Help color="danger">{formErrors.onboardingDate}</Form.Help>}
        </Form.Field>

        <Form.Field style={{ width: '25%', display: 'inline-block' }}>
          <Form.Label>Offboarding Date</Form.Label>
          <DatePicker disabled={isCohortAdmin} 
            popperPlacement="right" selected={modifiedSettings.offboardingDate && parseISO(modifiedSettings.offboardingDate)}
            onChange={(date) => updateCurrentGroupProp('offboardingDate', date.toISOString())}
            customInput={<CustomDateInput color={formErrors.offboardingDate ? 'danger' : ''} onFocus={() => console.log('offboardingDate focus')} />}
          />
          {formErrors.offboardingDate && <Form.Help color="danger">{formErrors.offboardingDate}</Form.Help>}
      </Form.Field>

      <Form.Field>
        <Form.Label>Mentorship duration</Form.Label>
        <div className='is-flex is-align-items-baseline'>
          <Form.Input disabled={isCohortAdmin} style={{ width: '5rem' }} type="number" color={formErrors.mentorshipDuration ? 'danger' : ''} value={modifiedSettings.mentorshipDuration} onChange={e => updateCurrentGroupProp('mentorshipDuration', e.target.value)}></Form.Input>
          <Form.Label className="ml-2">weeks.</Form.Label>
        </div>
        {formErrors.mentorshipDuration && <Form.Help color="danger">{formErrors.mentorshipDuration}</Form.Help>}
        </Form.Field>

      </div>

      <Form.Field>
        <Form.Label>Allow offboarding on mentorship completion</Form.Label>
        <div className="field">
          <input disabled={isCohortAdmin} id="allowOffboarding" type="checkbox" name="allowOffboarding" className="switch" checked={modifiedSettings.allowOffboardOnCompletion} onChange={e => { updateCurrentGroupProp('allowOffboardOnCompletion', e.target.checked) }} />
          <label htmlFor="allowOffboarding">{modifiedSettings.allowOffboardOnCompletion ? 'Allow' : 'Disallow'}</label>
        </div>
      </Form.Field>

      {currentGroup.id > 0 &&
        <Form.Field>
          <Form.Label>Cohort Status</Form.Label>
          <div className="field">
            <input disabled={isCohortAdmin} id="enabledSwitch" type="checkbox" name="enabledSwitch" className="switch" checked={currentGroup.enabled} onClick={e => { changeGroupEnabledState(!currentGroup.enabled); e.preventDefault(); e.stopPropagation(); }} />
            <label htmlFor="enabledSwitch">{currentGroup.enabled ? 'Active' : 'Inactive'}</label>
          </div>
        </Form.Field>
      }

      <div className="is-flex mt-3" style={{ width: '100%' }}>
        <div>
          <Form.Control style={{ width: 135 }}>
            <Form.Label>Signup QR Code</Form.Label>
            <QRCode id="signupQRCode" size={128} value={signupUrl} />
            <Button disabled={isCohortAdmin} className="is-outlined is-small" style={{ width: 128 }} onClick={() => downloadQR()}>Download</Button>
          </Form.Control>
        </div>
        <div className="ml-4" style={{ width: '100%' }}>
          <Form.Label>Signup Code</Form.Label>
          <Form.Field color="danger" className="has-addons is-danger">
            <Form.Control style={{ width: '10rem' }}>
              <Form.Input disabled={isCohortAdmin} color={formErrors.signupCode ? 'danger' : ''} value={modifiedSettings.signupCode} onChange={e => updateCurrentGroupProp('signupCode', e.target.value)}></Form.Input>
              {formErrors.signupCode && <Form.Help color="danger">{formErrors.signupCode}</Form.Help>}
            </Form.Control>
            <Form.Control color={formErrors.signupCode ? 'danger' : ''}>
              <Button disabled={isCohortAdmin} title="New Signup Code" onClick={() => askAndGenerateNewSignupCode()}><FontAwesomeIcon icon={faRedo} /></Button>
            </Form.Control>
          </Form.Field>
          <Form.Label>Signup Link</Form.Label>
          <Form.Field className="has-addons">
            <Form.Control className="is-expanded">
              <Form.Input value={signupUrl} disabled={true} style={{ backgroundColor: 'white' }}></Form.Input>
            </Form.Control>
            <Form.Control>
              <Button title="Copy" onClick={() => { navigator.clipboard.writeText(signupUrl); toast.success('Signup URL copied to clipboard'); }}><FontAwesomeIcon icon={faCopy} /></Button>
            </Form.Control>
          </Form.Field>
        </div>
      </div>
    </div>

    <div className="mt-3">
      <Button.Group>
        <Button disabled={!isModified || isSaving} className="is-success" loading={isSaving} onClick={() => saveModifications(modifiedSettings)}>Save</Button>
        <Button disabled={isSaving || allowCancel || (!allowCancel && !isModified)} className="is-outlined" onClick={cancelModifications}>Cancel</Button>
      </Button.Group>
    </div>
  </div>
}

export default GroupDetails;